import { createSlice } from "@reduxjs/toolkit";

const attendancesSlice = createSlice({
  name: "students",
  initialState: {
    attendances: [],
    attendance: {},
    students: [],
  },
  reducers: {
    setAttendance(state, action) {
      state.attendance = action.payload;
    },
    emptyAttendance(state, action) {
      state.attendance = {};
    },
    setStudents(state, action) {
      state.students = action.payload;
    },
    changeStatus(state, action) {
      return {
        ...state,
        students: state.students.map((student) =>
          student.id === action.payload.id ? action.payload : student
        ),
      };
    },
    loadAttendances(state, action) {
      state.attendances = action.payload;
    },
    addAttendance(state, action) {
      // const attendance = {
      //   id: action.payload.id,
      //   name: action.payload.name,
      //   phone_number: action.payload.phone_number,
      //   birth_dat: action.payload.birth_dat,
      //   address: action.payload.address,
      //   school_name: action.payload.school_name,
      //   group_id: action.payload.group_id,
      //   group: action.payload.group,
      // };
      // state.attendances.push(attendance);
    },
    removeAttendance(state, action) {
      state.attendances = state.attendances.filter(
        (attendance) => attendance.date !== action.payload
      );
    },
    updateAttendance(state, action) {
      return {
        ...state,
        students: state.attendances.map((attendance) =>
          attendance.id === action.payload.id ? action.payload : attendance
        ),
      };
    },
  },
});

export const attendancesActions = attendancesSlice.actions;

export default attendancesSlice;
