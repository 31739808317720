import {
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  Button,
  Spacer,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  Divider,
  RadioGroup,
  Radio,
  Stack,
  useToast,
  FormErrorMessage,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../store/uiSlice";
import Loading from "../pages/Loading";
import { variables } from "../constants";

function AddAttendance({ open, onClose }) {
  const dispatch = useDispatch();
  const toast = useToast();

  const groups = useSelector((state) => state.groups.groups);
  const loading = useSelector((state) => state.ui.loading);

  const [date, setDate] = useState("");
  const [group, setGroup] = useState("");
  const [students, setStudents] = useState([]);
  const [errors, setErrors] = useState({});

  const handelOnChangeGroup = (id) => {
    setGroup(id);
    axios
      .post(`${variables.url_api}/api/attendances/show/${id}`)
      .then((res) => {
        let newStudents = [];
        setStudents([]);
        res.data.students.map((student) => {
          newStudents.push({
            id: student.id,
            name: student.name,
            status: "حاضر",
          });
        });
        setStudents([...newStudents]);
      })
      .catch((error) => console.log(error));
  };

  const handelOnChangeAttendance = (index, value) => {
    const updatedStudents = [...students];
    updatedStudents[index].status = value;
    setStudents([...updatedStudents]);
  };

  const openToast = (type) => {
    if (type === "success") {
      toast({
        title: "تم اضافة بيانات الحضور بنجاح",
        variant: "left-accent",
        position: "bottom-right",
        status: type,
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: "حصل خطأ في الاضافة",
        variant: "left-accent",
        position: "bottom-right",
        status: type,
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const validate = () => {
    let valid = true;
    let errors = {};

    if (!date) {
      valid = false;
      errors.date = "تاريخ الحضور لا يمكن ان يكون فارغاً";
    }

    if (!group) {
      valid = false;
      errors.group = "يجب اختيار كروب الطالب";
    }

    setErrors(errors);
    return valid;
  };

  const handelAdd = () => {
    if (validate()) {
      dispatch(uiActions.setLoading(true));
      axios
        .post(`${variables.url_api}/api/attendances/create`, {
          date,
          students,
        })
        .then((res) => {
          setDate("");
          setGroup("");
          setStudents([]);
          dispatch(uiActions.setLoading(false));
          openToast("success");
          onClose();
        })
        .catch((error) => {
          openToast("error");
          dispatch(uiActions.setLoading(false));
          console.log(error);
        });
    }
  };

  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalOverlay />
      <ModalContent sx={{ minWidth: "60%" }}>
        <ModalCloseButton sx={{ left: "1rem", right: "auto" }} />
        <Spacer />
        <ModalHeader>تسجيل حضور الطلاب</ModalHeader>
        <ModalBody>
          <Grid templateColumns="repeat(12, 1fr)" gap="5px">
            <GridItem colSpan={7}>
              <FormControl isInvalid={errors.group}>
                <FormLabel>المجموعة</FormLabel>
                <Select
                  placeholder="اختار مجوعة الطلاب"
                  value={group}
                  onChange={(e) => handelOnChangeGroup(e.target.value)}
                >
                  {groups.map((g) => (
                    <option key={g.id} value={g.id}>
                      {g.name}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.group}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={5}>
              <FormControl isInvalid={errors.date}>
                <FormLabel>تاريخ المحاضرة</FormLabel>
                <Input
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
                <FormErrorMessage>{errors.date}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={12}>
              <Text className="mt-5 text-xl">الطلاب</Text>
              <Divider />
            </GridItem>
            {students.map((student, i) => (
              <GridItem key={i} colSpan={12}>
                <div className="flex gap-5 justify-start my-2">
                  <Text>{student.name}</Text>
                  <Spacer />
                  <RadioGroup
                    className="ml-10"
                    onChange={(e) => handelOnChangeAttendance(i, e)}
                    value={student.status}
                  >
                    <Stack spacing={5} direction="row">
                      <Radio colorScheme="green" value="حاضر">
                        حاضر
                      </Radio>
                      <Radio colorScheme="orange" value="مجاز">
                        مجاز
                      </Radio>
                      <Radio colorScheme="red" value="غائب">
                        غائب
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </div>
              </GridItem>
            ))}
          </Grid>
        </ModalBody>

        <ModalFooter>
          <Button
            variant="ghost"
            onClick={() => {
              onClose();
              setDate("");
              setGroup("");
              setStudents([]);
              setErrors({});
            }}
          >
            الغاء
          </Button>
          <Button colorScheme="blue" mr={3} onClick={handelAdd}>
            اضافة
          </Button>
        </ModalFooter>
        {loading && <Loading />}
      </ModalContent>
    </Modal>
  );
}

export default AddAttendance;
