import {
  Box,
  HStack,
  Button,
  Spacer,
  Select,
  VStack,
  Text,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  faCheck,
  faMinus,
  faPenToSquare,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import AddAttendance from "../components/AddAttendance";
import { attendancesActions } from "../store/attendancesSlice";
import EditAttendance from "../components/EditAttendance";
import DeleteDialog from "../components/DeleteDialog";
import { uiActions } from "../store/uiSlice";
import { variables } from "../constants";

function Attendances() {
  const dispatch = useDispatch();
  const toast = useToast();

  const user = useSelector((state) => state.auth);
  const groups = useSelector((state) => state.groups.groups);
  const days = [
    "الاحد",
    "الاثنين",
    "الثلاثاء",
    "الاربعاء",
    "الخميس",
    "الجمعة",
    "السبت",
  ];

  const [group, setGroup] = useState("");
  const [attendance, setAttendance] = useState("");
  const [students, setStudents] = useState([]);
  const [attendances, setAttendances] = useState([]);

  const [openAddModel, setOpenAddModel] = useState(false);
  const [openShowModel, setOpenShowModel] = useState(false);
  const [openEditModel, setOpenEditModel] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const openToast = (type) => {
    if (type === "success") {
      toast({
        title: "تم حذف بيانات الحضور بنجاح",
        variant: "left-accent",
        position: "bottom-right",
        status: type,
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: "حصل خطأ في الحذف",
        variant: "left-accent",
        position: "bottom-right",
        status: type,
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handelOpenShowModel = (student) => {
    setOpenShowModel(true);
  };

  const handelOpenEditModel = (attendance) => {
    axios
      .post(`${variables.url_api}/api/attendances/edit`, {
        date: attendance.date,
      })
      .then((res) => {
        const studentsArray = [];
        dispatch(attendancesActions.setAttendance({ date: attendance.date }));
        res.data.forEach((att) => {
          studentsArray.push({
            id: att.student.id,
            name: att.student.name,
            status: att.status,
          });
        });
        dispatch(attendancesActions.setStudents([...studentsArray]));
      });
    setOpenEditModel(true);
  };

  const handelDelete = (attendance) => {
    dispatch(uiActions.setLoading(true));
    axios
      .post(`${variables.url_api}/api/attendances/delete`, {
        date: attendance.date,
      })
      .then((res) => {
        // dispatch(attendancesActions.removeAttendances(attendance));
        setOpenDeleteDialog(false);
        dispatch(uiActions.setLoading(false));
        openToast("success");
        setOpenDeleteDialog(false);
      })
      .catch((error) => {
        openToast("error");
        dispatch(uiActions.setLoading(false));
        console.log(error);
      });
  };

  const handelOnChange = (id) => {
    setGroup(id);
    axios
      .post(`${variables.url_api}/api/attendances/show/${id}`)
      .then((res) => {
        setStudents(res.data.students);
        setAttendances(res.data.students[0].attendances);
      })
      .catch((error) => console.log(error));
  };
  return (
    <Box
      h="100%"
      p="5px"
      overflow="scroll"
      className="bg-white no-scrollbar"
      borderTopWidth={5}
      borderTopColor="#60a5fa"
      borderRadius="lg"
    >
      <HStack pt="10px" pb="20px" px="20px">
        <span>
          <FontAwesomeIcon
            className="w-16 text-center"
            icon={faCheck}
            color="#22c55e"
            size="xl"
          />
          <text>حاضر</text>
        </span>
        <span>
          <FontAwesomeIcon
            className="w-16 text-center"
            icon={faMinus}
            color="#eab308"
            size="xl"
          />
          <text>مجاز</text>
        </span>
        <span>
          <FontAwesomeIcon
            className="w-16 text-center"
            icon={faXmark}
            color="#ef4444"
            size="xl"
          />
          <text>غائب</text>
        </span>
        <Spacer />
        <Button colorScheme="blue" onClick={() => setOpenAddModel(true)}>
          اضافة حضور
        </Button>
      </HStack>
      <HStack pt="10px" pb="20px" px="20px">
        <Select
          placeholder="اختار مجوعة الطلاب"
          value={group}
          onChange={(e) => handelOnChange(e.target.value)}
        >
          {groups.map((g) => (
            <option key={g.id} value={g.id}>
              {g.name}
            </option>
          ))}
        </Select>
      </HStack>
      <div className="w-full overflow-scroll no-scrollbar">
        <div className="flex w-fit gap-5 justify-start">
          <Text className="font-bold w-96 text-slate-500">اسم الطالب</Text>
          {attendances.map((attendance, i) => (
            <div key={i}>
              <Text className="w-16 text-xs text-center font-bold text-slate-500">
                {days[new Date(attendance.date).getDay()]}
              </Text>
              <Text className="w-16 text-xs text-center font-bold text-slate-500">
                {attendance.date}
              </Text>
              <IconButton
                bg="transparent"
                borderRadius="3xl"
                size="sm"
                aria-label="تعديل بيانات الحضور"
                onClick={() => handelOpenEditModel(attendance)}
                icon={
                  <FontAwesomeIcon
                    className="text-blue-400"
                    icon={faPenToSquare}
                  />
                }
              />
              <IconButton
                bg="transparent"
                borderRadius="3xl"
                size="sm"
                aria-label="حذف بيانات الحضور"
                onClick={() => {
                  setOpenDeleteDialog(true);
                  setAttendance(attendance);
                }}
                icon={
                  <FontAwesomeIcon
                    className="text-blue-400"
                    icon={faTrashCan}
                  />
                }
              />
            </div>
          ))}
        </div>
        {students.map((student, i) => (
          <div key={i} className="flex w-fit gap-5 justify-start pt-5">
            <Text className="w-96">{student.name}</Text>
            {student.attendances.map((att, index) =>
              att.status === "حاضر" ? (
                <FontAwesomeIcon
                  key={index}
                  className="w-16 text-center"
                  icon={faCheck}
                  color="#22c55e"
                  size="xl"
                />
              ) : att.status === "مجاز" ? (
                <FontAwesomeIcon
                  key={index}
                  className="w-16 text-center"
                  icon={faMinus}
                  color="#eab308"
                  size="xl"
                />
              ) : (
                <FontAwesomeIcon
                  key={index}
                  className="w-16 text-center"
                  icon={faXmark}
                  color="#ef4444"
                  size="xl"
                />
              )
            )}
          </div>
        ))}
      </div>
      <AddAttendance
        open={openAddModel}
        onClose={() => setOpenAddModel(false)}
      />
      <EditAttendance
        open={openEditModel}
        onClose={() => setOpenEditModel(false)}
        group={group}
      />
      <DeleteDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        message={"هل انت متأكد من حذف بيانات الحضور"}
        title={"حذف بيانات الحضور"}
        onDelete={() => handelDelete(attendance)}
      />
    </Box>
  );
}

export default Attendances;
