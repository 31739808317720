import {
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  Button,
  Spacer,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Input,
  useToast,
  FormErrorMessage,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { groupsActions } from "../store/groupsSlice";
import axios from "axios";
import { uiActions } from "../store/uiSlice";
import Loading from "../pages/Loading";
import { variables } from "../constants";

function EditGroup({ open, onClose }) {
  const dispatch = useDispatch();
  const toast = useToast();

  const group = useSelector((state) => state.groups.group);
  const loading = useSelector((state) => state.ui.loading);

  const [errors, setErrors] = useState("");

  const openToast = (type) => {
    if (type === "success") {
      toast({
        title: "تم تحديث بيانات المجموعة بنجاح",
        variant: "left-accent",
        position: "bottom-right",
        status: type,
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: "حصل خطأ في تحديث بيانات المجموعة",
        variant: "left-accent",
        position: "bottom-right",
        status: type,
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const validate = () => {
    let valid = true;
    let errors = {};

    if (!group["name"]) {
      valid = false;
      errors.name = "اسم المجموعة لا يمكن ان يكون فارغاً";
    }

    if (!group["bg_color"]) {
      valid = false;
      errors.bgColor = "يجب تحديد لون المجموعة";
    }

    setErrors(errors);
    return valid;
  };

  const handelOnChange = (label, value) => {
    let updatedGroup = { ...group };
    updatedGroup[label] = value;

    dispatch(groupsActions.setGroup(updatedGroup));

    // let id = updatedStudent.id;
    // axios
    //   .post(`${variables.url_api}/api/students/update/${id}`, updatedStudent)
    //   .then((res) => {
    //     dispatch(studentsActions.updatedStudent(updatedStudent));
    //     onClose();
    //   })
    //   .catch((error) => console.log(error));
  };

  const handelUpdate = () => {
    let id = group.id;
    if (validate()) {
      dispatch(uiActions.setLoading(true));
      axios
        .post(`${variables.url_api}/api/groups/update/${id}`, group)
        .then((res) => {
          dispatch(groupsActions.updateGroup(res.data));
          dispatch(uiActions.setLoading(false));
          openToast("success");
          onClose();
        })
        .catch((error) => {
          openToast("error");
          dispatch(uiActions.setLoading(false));
          console.log(error);
        });
    }
  };

  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalOverlay />
      <ModalContent sx={{ minWidth: "60%" }}>
        <ModalCloseButton sx={{ left: "1rem", right: "auto" }} />
        <Spacer />
        <ModalHeader>تعديل بيانات المجموعة</ModalHeader>
        <ModalBody>
          <Grid templateColumns="repeat(12, 1fr)" gap="5px">
            <GridItem colSpan={12}>
              <FormControl isInvalid={errors.name}>
                <FormLabel>اسم المجموعة</FormLabel>
                <Input
                  value={group.name}
                  onChange={(e) => handelOnChange("name", e.target.value)}
                />
                <FormErrorMessage>{errors.name}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.bgColor}>
                <FormLabel>اللون</FormLabel>
                <Input
                  value={group.bg_color}
                  onChange={(e) => handelOnChange("bg_color", e.target.value)}
                />
                <FormErrorMessage>{errors.bgColor}</FormErrorMessage>
              </FormControl>
            </GridItem>
          </Grid>
        </ModalBody>

        <ModalFooter>
          <Button
            variant="ghost"
            onClick={() => {
              onClose();
              setErrors({});
            }}
          >
            الغاء
          </Button>
          <Button colorScheme="blue" mr={3} onClick={handelUpdate}>
            تعديل
          </Button>
        </ModalFooter>
        {loading && <Loading />}
      </ModalContent>
    </Modal>
  );
}

export default EditGroup;
