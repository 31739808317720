import {
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  Button,
  Spacer,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
  FormErrorMessage,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { questionsAction } from "../store/questionsSlice";
import UploadFile from "./UploadFile";
import { uiActions } from "../store/uiSlice";
import Loading from "../pages/Loading";
import { variables } from "../constants";

function AddQuestion({ open, onClose }) {
  const dispatch = useDispatch();
  const toast = useToast();

  const subjects = useSelector((state) => state.subjects.subjects);
  const loading = useSelector((state) => state.ui.loading);

  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [file, setFile] = useState("");
  const [errors, setErrors] = useState("");

  const onDrop = (acceptedFiles) => {
    console.log(acceptedFiles[0]);
    setFile(acceptedFiles[0]);
  };

  const openToast = (type) => {
    if (type === "success") {
      toast({
        title: "تم اضافة الاسالة بنجاح",
        variant: "left-accent",
        position: "bottom-right",
        status: type,
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: "حصل خطأ في الاضافة",
        variant: "left-accent",
        position: "bottom-right",
        status: type,
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const validate = () => {
    let valid = true;
    let errors = {};

    if (!name) {
      valid = false;
      errors.name = "اسم الاسالة لا يمكن ان يكون فارغاً";
    }

    if (!subject) {
      valid = false;
      errors.subject = "يجب اختيار الموضوع";
    }

    setErrors(errors);
    return valid;
  };

  const handelAdd = () => {
    if (validate()) {
      dispatch(uiActions.setLoading(true));
      const formData = new FormData();
      formData.append("name", name);
      formData.append("subject_id", subject);
      formData.append("file", file);
      axios
        .post(`${variables.url_api}/api/questions/create`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          dispatch(questionsAction.addQuestion(res.data));
          dispatch(uiActions.setLoading(false));
          openToast("success");
          onClose();
        })
        .catch((error) => {
          openToast("error");
          dispatch(uiActions.setLoading(false));
          console.log(error);
        });
    }
  };
  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalOverlay />
      <ModalContent sx={{ minWidth: "60%" }}>
        <ModalCloseButton sx={{ left: "1rem", right: "auto" }} />
        <Spacer />
        <ModalHeader>اضافة اسالة جديدة</ModalHeader>
        <ModalBody>
          <Grid templateColumns="repeat(12, 1fr)" gap="5px">
            <GridItem colSpan={7}>
              <FormControl isInvalid={errors.name}>
                <FormLabel>اسم الاسالة</FormLabel>
                <Input value={name} onChange={(e) => setName(e.target.value)} />
                <FormErrorMessage>{errors.name}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={5}>
              <FormControl isInvalid={errors.subject}>
                <FormLabel>الموضوع</FormLabel>
                <Select
                  placeholder="اختار الموضوع"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                >
                  {subjects.map((s) => (
                    <option key={s.id} value={s.id}>
                      {s.name}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.subject}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={12}>
              <FormControl h="150">
                <FormLabel>ملف الاسالة</FormLabel>
                <UploadFile onDrop={onDrop} file={file} />
              </FormControl>
            </GridItem>
          </Grid>
        </ModalBody>

        <ModalFooter>
          <Button
            variant="ghost"
            onClick={() => {
              onClose();
              setErrors({});
            }}
          >
            الغاء
          </Button>
          <Button colorScheme="blue" mr={3} onClick={handelAdd}>
            اضافة
          </Button>
        </ModalFooter>
        {loading && <Loading />}
      </ModalContent>
    </Modal>
  );
}

export default AddQuestion;
