import {
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  Button,
  Spacer,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
  FormErrorMessage,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { studentsActions } from "../store/studentsSlice";
import { uiActions } from "../store/uiSlice";
import Loading from "../pages/Loading";
import { variables } from "../constants";

function EditStudent({ open, onClose }) {
  const dispatch = useDispatch();
  const toast = useToast();

  const student = useSelector((state) => state.students.student);
  const groups = useSelector((state) => state.groups.groups);
  const loading = useSelector((state) => state.ui.loading);

  const [errors, setErrors] = useState({});

  const openToast = (type) => {
    if (type === "success") {
      toast({
        title: "تم تحديث بيانات الطالب بنجاح",
        variant: "left-accent",
        position: "bottom-right",
        status: type,
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: "حصل خطأ في التحديث",
        variant: "left-accent",
        position: "bottom-right",
        status: type,
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const validate = () => {
    let valid = true;
    let errors = {};

    if (!student["name"]) {
      valid = false;
      errors.name = "اسم الطالب لا يمكن ان يكون فارغ";
    }

    if (!student["phone_number"]) {
      valid = false;
      errors.phone = "رقم الهاتف لا يمكن ان يكون فارغ";
    } else if (student["phone_number"].length !== 11) {
      valid = false;
      errors.phone = "رقم الهاتف غير صحيح";
    }

    if (!student["parent_phone_number"]) {
      valid = false;
      errors.parentPhone = "رقم الهاتف لا يمكن ان يكون فارغ";
    } else if (student["parent_phone_number"].length !== 11) {
      valid = false;
      errors.parentPhone = "رقم الهاتف غير صحيح";
    }

    if (!student["group_id"]) {
      valid = false;
      errors.group = "يجب اختيار كروب الطالب";
    }

    setErrors(errors);
    return valid;
  };

  const handelOnChange = (label, value) => {
    let updatedStudent = { ...student };
    updatedStudent[label] = value;

    dispatch(studentsActions.setStudent(updatedStudent));
  };

  const handelUpdate = () => {
    let id = student.id;
    if (validate()) {
      dispatch(uiActions.setLoading(true));
      axios
        .post(`${variables.url_api}/api/students/update/${id}`, student)
        .then((res) => {
          dispatch(studentsActions.updateStudent(res.data));
          dispatch(uiActions.setLoading(false));
          openToast("success");
          onClose();
        })
        .catch((error) => {
          openToast("error");
          dispatch(uiActions.setLoading(false));
          console.log(error);
        });
    }
  };
  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalOverlay />
      <ModalContent sx={{ minWidth: "60%" }}>
        <ModalCloseButton sx={{ left: "1rem", right: "auto" }} />
        <Spacer />
        <ModalHeader>تعديل بيانات الطالب</ModalHeader>
        <ModalBody>
          <Grid templateColumns="repeat(12, 1fr)" gap="5px">
            <GridItem colSpan={12}>
              <FormControl isInvalid={errors.name}>
                <FormLabel>الاسم</FormLabel>
                <Input
                  value={student.name}
                  onChange={(e) => handelOnChange("name", e.target.value)}
                />
                <FormErrorMessage>{errors.name}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={6}>
              <FormControl isInvalid={errors.phone}>
                <FormLabel>رقم الموبايل</FormLabel>
                <Input
                  value={student.phone_number}
                  maxLength={11}
                  onChange={(e) =>
                    handelOnChange("phone_number", e.target.value)
                  }
                />
                <FormErrorMessage>{errors.phone}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={6}>
              <FormControl isInvalid={errors.parentPhone}>
                <FormLabel>رقم موبايل ولي الامر</FormLabel>
                <Input
                  value={student.parent_phone_number}
                  maxLength={11}
                  onChange={(e) =>
                    handelOnChange("parent_phone_number", e.target.value)
                  }
                />
                <FormErrorMessage>{errors.parentPhone}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={4}>
              <FormControl>
                <FormLabel>تاريخ الميلاد</FormLabel>
                <Input
                  type="date"
                  value={student.birth_date}
                  onChange={(e) => handelOnChange("birth_date", e.target.value)}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={4}>
              <FormControl>
                <FormLabel>عنوان السكن</FormLabel>
                <Input
                  value={student.address}
                  onChange={(e) => handelOnChange("address", e.target.value)}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={4}>
              <FormControl>
                <FormLabel>اسم المدرسة</FormLabel>
                <Input
                  value={student.school_name}
                  onChange={(e) =>
                    handelOnChange("school_name", e.target.value)
                  }
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={12}>
              <FormControl isInvalid={errors.group}>
                <FormLabel>المجموعة</FormLabel>
                <Select
                  placeholder="اختار مجوعة الطلاب"
                  value={student.group_id}
                  onChange={(e) => handelOnChange("group_id", e.target.value)}
                >
                  {groups.map((g) => (
                    <option key={g.id} value={g.id}>
                      {g.name}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.group}</FormErrorMessage>
              </FormControl>
            </GridItem>
          </Grid>
        </ModalBody>

        <ModalFooter>
          <Button
            variant="ghost"
            onClick={() => {
              onClose();
              setErrors({});
            }}
          >
            الغاء
          </Button>
          <Button colorScheme="blue" mr={3} onClick={handelUpdate}>
            تعديل
          </Button>
        </ModalFooter>
        {loading && <Loading />}
      </ModalContent>
    </Modal>
  );
}

export default EditStudent;
