import { Progress, Spinner } from "@chakra-ui/react";
import React from "react";

function Loading() {
  return (
    <>
      <div className="w-screen h-screen z-50 bg-black fixed top-0 left-0 flex justify-center content-center opacity-30">
        <Spinner
          className="self-center"
          thickness="4px"
          speed="0.65s"
          color="blue.500"
          size="xl"
        />
      </div>
    </>
  );
}

export default Loading;
