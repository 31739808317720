import React from "react";
import { Box, VStack } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faUsers,
  faPeopleRoof,
  faBook,
  faFile,
  faFilePen,
  faCalendarDays,
} from "@fortawesome/free-solid-svg-icons";

function SideNav() {
  return (
    <Box h="100%" className="bg-slate-800 pt-5" boxShadow="dark-lg">
      <VStack spacing="5px">
        <NavLink
          to="/"
          className={({ isActive, isPending, isTransitioning }) =>
            [
              isActive
                ? "w-11/12 text-blue-500 text-lg p-2 rounded bg-slate-700 "
                : "w-11/12 text-slate-300 text-lg p-2",
            ].join(" ")
          }
        >
          <FontAwesomeIcon icon={faHouse} />
          <span className="px-2">الرئيسية</span>
        </NavLink>
        <NavLink
          to="/students"
          className={({ isActive, isPending, isTransitioning }) =>
            [
              isActive
                ? "w-11/12 text-blue-500 text-lg p-2 rounded bg-slate-700 "
                : "w-11/12 text-slate-300 text-lg p-2",
            ].join(" ")
          }
        >
          <FontAwesomeIcon icon={faUsers} />
          <span className="px-2">الطلاب</span>
        </NavLink>
        {/* <Divider className="my-3" />
        <span className="w-full text-slate-300 text-lg p-3">ثوابت النظام</span> */}
        <NavLink
          to="/groups"
          className={({ isActive, isPending, isTransitioning }) =>
            [
              isActive
                ? "w-11/12 text-blue-500 text-lg p-2 rounded bg-slate-700 "
                : "w-11/12 text-slate-300 text-lg p-2",
            ].join(" ")
          }
        >
          <FontAwesomeIcon icon={faPeopleRoof} />
          <span className="px-2">المجموعات</span>
        </NavLink>
        <NavLink
          to="/subjects"
          className={({ isActive, isPending, isTransitioning }) =>
            [
              isActive
                ? "w-11/12 text-blue-500 text-lg p-2 rounded bg-slate-700 "
                : "w-11/12 text-slate-300 text-lg p-2",
            ].join(" ")
          }
        >
          <FontAwesomeIcon icon={faBook} />
          <span className="px-2">المواضيع</span>
        </NavLink>
        <NavLink
          to="/questions"
          className={({ isActive, isPending, isTransitioning }) =>
            [
              isActive
                ? "w-11/12 text-blue-500 text-lg p-2 rounded bg-slate-700 "
                : "w-11/12 text-slate-300 text-lg p-2",
            ].join(" ")
          }
        >
          <FontAwesomeIcon icon={faFile} />
          <span className="px-2">الاسالة</span>
        </NavLink>
        <NavLink
          to="/exams"
          className={({ isActive, isPending, isTransitioning }) =>
            [
              isActive
                ? "w-11/12 text-blue-500 text-lg p-2 rounded bg-slate-700 "
                : "w-11/12 text-slate-300 text-lg p-2",
            ].join(" ")
          }
        >
          <FontAwesomeIcon icon={faFilePen} />
          <span className="px-2">الامتحانات</span>
        </NavLink>
        <NavLink
          to="/attendances"
          className={({ isActive, isPending, isTransitioning }) =>
            [
              isActive
                ? "w-11/12 text-blue-500 text-lg p-2 rounded bg-slate-700 "
                : "w-11/12 text-slate-300 text-lg p-2",
            ].join(" ")
          }
        >
          <FontAwesomeIcon icon={faFilePen} />
          <span className="px-2">الحضور</span>
        </NavLink>
        <NavLink
          to="/tables"
          className={({ isActive, isPending, isTransitioning }) =>
            [
              isActive
                ? "w-11/12 text-blue-500 text-lg p-2 rounded bg-slate-700 "
                : "w-11/12 text-slate-300 text-lg p-2",
            ].join(" ")
          }
        >
          <FontAwesomeIcon icon={faCalendarDays} />
          <span className="px-2">الجدول</span>
        </NavLink>
      </VStack>
    </Box>
  );
}

export default SideNav;
