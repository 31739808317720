import {
  Box,
  HStack,
  Button,
  Spacer,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddSubject from "../components/AddSubject";
import ShowSubject from "../components/ShowSubject";
import { subjectsActions } from "../store/subjectsSlice";
import axios from "axios";
import EditSubject from "../components/EditSubject";
import DeleteDialog from "../components/DeleteDialog";
import { faFile, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { uiActions } from "../store/uiSlice";
import Loading from "./Loading";
import { variables } from "../constants";

function Subjects() {
  const dispatch = useDispatch();
  const toast = useToast();

  const user = useSelector((state) => state.auth);
  const subjects = useSelector((state) => state.subjects.subjects);
  const loading = useSelector((state) => state.ui.loading);

  const [openAddModel, setOpenAddModel] = useState(false);
  const [openShowModel, setOpenShowModel] = useState(false);
  const [openEditModel, setOpenEditModel] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [subject, setSubject] = useState(null);
  const [id, setId] = useState(null);

  const openToast = (type) => {
    if (type === "success") {
      toast({
        title: "تم حذف الموضوع بنجاح",
        variant: "left-accent",
        position: "bottom-right",
        status: type,
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: "حصل خطأ في الحذف",
        variant: "left-accent",
        position: "bottom-right",
        status: type,
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handelOpenShowModel = (student) => {
    dispatch(uiActions.setLoading(true));
    setSubject(student);
    setOpenShowModel(true);
    dispatch(uiActions.setLoading(false));
  };
  const handelOpenEditModel = (student) => {
    dispatch(subjectsActions.setSubject(student));
    setOpenEditModel(true);
  };

  const handelDelete = (id) => {
    dispatch(uiActions.setLoading(true));
    axios
      .post(`${variables.url_api}/api/subjects/delete/${id}`)
      .then((res) => {
        dispatch(subjectsActions.removeSubject(id));
        dispatch(uiActions.setLoading(false));
        openToast("success");
        setOpenDeleteDialog(false);
      })
      .catch((error) => {
        openToast("error");
        dispatch(uiActions.setLoading(false));
        console.log(error);
      });
  };

  return (
    <Box
      h="100%"
      p="5px"
      overflow="scroll"
      className="bg-white"
      borderTopWidth={5}
      borderTopColor="#60a5fa"
      borderRadius="lg"
    >
      <HStack pt="10px" pb="20px" px="20px">
        <Spacer />
        <Button colorScheme="blue" onClick={() => setOpenAddModel(true)}>
          اضافة موضوع
        </Button>
      </HStack>
      <TableContainer>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>اسم الموضوع</Th>
              <Th>نوعه</Th>
              <Th>التحكم</Th>
            </Tr>
          </Thead>
          <Tbody>
            {subjects.map((subject) => (
              <Tr key={subject.id}>
                <Td>{subject.name}</Td>
                <Td>{subject.type}</Td>
                <Td>
                  <IconButton
                    bg="transparent"
                    borderRadius="3xl"
                    aria-label="عرض بيانات الطالب"
                    onClick={() => handelOpenShowModel(subject)}
                    icon={
                      <FontAwesomeIcon
                        className="text-blue-400"
                        icon={faFile}
                      />
                    }
                  />
                  <IconButton
                    bg="transparent"
                    borderRadius="3xl"
                    aria-label="تعديل بيانات الطالب"
                    onClick={() => handelOpenEditModel(subject)}
                    icon={
                      <FontAwesomeIcon
                        className="text-blue-400"
                        icon={faPenToSquare}
                      />
                    }
                  />
                  <IconButton
                    bg="transparent"
                    borderRadius="3xl"
                    aria-label="حذف بيانات الطالب"
                    onClick={() => {
                      setOpenDeleteDialog(true);
                      setId(subject.id);
                    }}
                    icon={
                      <FontAwesomeIcon
                        className="text-blue-400"
                        icon={faTrashCan}
                      />
                    }
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <AddSubject open={openAddModel} onClose={() => setOpenAddModel(false)} />
      <ShowSubject
        open={openShowModel}
        onClose={() => setOpenShowModel(false)}
        subject={subject}
      />
      <EditSubject
        open={openEditModel}
        onClose={() => setOpenEditModel(false)}
      />
      <DeleteDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        message={"هل انت متأكد من حذف الموضوع"}
        title={"حذف الموضوع"}
        onDelete={() => handelDelete(id)}
      />
      {loading && <Loading />}
    </Box>
  );
}

export default Subjects;
