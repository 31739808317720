import {
  Box,
  HStack,
  Button,
  Spacer,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddStudent from "../components/AddStudent";
import ShowStudent from "../components/ShowStudent";
import { faFile, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import EditStudent from "../components/EditStudent";
import { studentsActions } from "../store/studentsSlice";
import DeleteDialog from "../components/DeleteDialog";
import axios from "axios";
import { uiActions } from "../store/uiSlice";
import Loading from "./Loading";
import { variables } from "../constants";

function Students() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const user = useSelector((state) => state.auth);
  const students = useSelector((state) => state.students.students);
  const loading = useSelector((state) => state.ui.loading);

  const [openAddModel, setOpenAddModel] = useState(false);
  const [openShowModel, setOpenShowModel] = useState(false);
  const [openEditModel, setOpenEditModel] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [student, setStudent] = useState(null);
  const [id, setId] = useState(null);

  // useEffect(() => {
  //   axios
  //     .post(`${variables}/api/students`)
  //     .then((res) => {
  //       dispatch(studentsActions.loadStudents(res.data));
  //     })
  //     .catch((error) => console.log(error));
  // });

  const openToast = (type) => {
    if (type === "success") {
      toast({
        title: "تم حذف بيانات الطالب بنجاح",
        variant: "left-accent",
        position: "bottom-right",
        status: type,
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: "حصل خطأ في الحذف",
        variant: "left-accent",
        position: "bottom-right",
        status: type,
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handelOpenShowModel = (student) => {
    dispatch(uiActions.setLoading(true));
    axios
      .post(`${variables.url_api}/api/students/show/${student.id}`)
      .then((res) => {
        let student = res.data.student;
        student.group = res.data.group;
        student.attendances = res.data.attendances;
        student.grades = res.data.grades;

        dispatch(studentsActions.setStudent({ ...student }));
        dispatch(uiActions.setLoading(false));
        navigate("/studentinfo");
      })
      .catch((error) => {
        dispatch(uiActions.setLoading(false));
        console.log(error);
      });
  };
  const handelOpenEditModel = (student) => {
    dispatch(studentsActions.setStudent(student));
    setOpenEditModel(true);
  };

  const handelDelete = (id) => {
    dispatch(uiActions.setLoading(true));
    axios
      .post(`${variables.url_api}/api/students/delete/${id}`)
      .then((res) => {
        dispatch(studentsActions.removeStudent(id));
        dispatch(uiActions.setLoading(false));
        openToast("success");
        setOpenDeleteDialog(false);
      })
      .catch((error) => {
        openToast("error");
        dispatch(uiActions.setLoading(false));
        console.log(error);
      });
  };

  return (
    <Box
      h="100%"
      p="5px"
      overflow="scroll"
      className="bg-white"
      borderTopWidth={5}
      borderTopColor="#60a5fa"
      borderRadius="lg"
    >
      <HStack pt="10px" pb="20px" px="20px">
        <Spacer />
        <Button colorScheme="blue" onClick={() => setOpenAddModel(true)}>
          اضافة طالب
        </Button>
      </HStack>
      <TableContainer>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>الاسم</Th>
              <Th>رقم الموبايل</Th>
              <Th>رقم موبايل ولي الامر</Th>
              <Th>المجموعة</Th>
              <Th>التحكم</Th>
            </Tr>
          </Thead>
          <Tbody>
            {students.map((student, i) => (
              <Tr key={i}>
                <Td>{student.name}</Td>
                <Td>{student.phone_number}</Td>
                <Td>{student.parent_phone_number}</Td>
                <Td>{student.group ? student.group.name : "-"}</Td>
                <Td>
                  <IconButton
                    bg="transparent"
                    borderRadius="3xl"
                    aria-label="عرض بيانات الطالب"
                    onClick={() => handelOpenShowModel(student)}
                    icon={
                      <FontAwesomeIcon
                        className="text-blue-400"
                        icon={faFile}
                      />
                    }
                  />
                  <IconButton
                    bg="transparent"
                    borderRadius="3xl"
                    aria-label="تعديل بيانات الطالب"
                    onClick={() => handelOpenEditModel(student)}
                    icon={
                      <FontAwesomeIcon
                        className="text-blue-400"
                        icon={faPenToSquare}
                      />
                    }
                  />
                  <IconButton
                    bg="transparent"
                    borderRadius="3xl"
                    aria-label="حذف بيانات الطالب"
                    onClick={() => {
                      setOpenDeleteDialog(true);
                      setId(student.id);
                    }}
                    icon={
                      <FontAwesomeIcon
                        className="text-blue-400"
                        icon={faTrashCan}
                      />
                    }
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <AddStudent open={openAddModel} onClose={() => setOpenAddModel(false)} />
      <ShowStudent
        open={openShowModel}
        onClose={() => setOpenShowModel(false)}
        student={student}
      />
      <EditStudent
        open={openEditModel}
        onClose={() => setOpenEditModel(false)}
      />
      <DeleteDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        message={"هل انت متأكد من حذف بيانات الطلب"}
        title={"حذف بيانات الطالب"}
        onDelete={() => handelDelete(id)}
      />
      {loading && <Loading />}
    </Box>
  );
}

export default Students;
