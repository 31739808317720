import { createSlice } from "@reduxjs/toolkit";

const studentsSlice = createSlice({
  name: "students",
  initialState: {
    students: [],
    student: {},
    currentPage: 1,
  },
  reducers: {
    setStudent(state, action) {
      state.student = action.payload;
    },
    emptyStudent(state, action) {
      state.student = {};
    },
    loadStudents(state, action) {
      state.students = action.payload;
    },
    addStudent(state, action) {
      const student = {
        id: action.payload.id,
        name: action.payload.name,
        phone_number: action.payload.phone_number,
        parent_phone_number: action.payload.parent_phone_number,
        birth_date: action.payload.birth_date,
        address: action.payload.address,
        school_name: action.payload.school_name,
        group_id: action.payload.group_id,
        group: action.payload.group,
      };
      state.students.push(student);
    },
    removeStudent(state, action) {
      state.students = state.students.filter(
        (student) => student.id !== action.payload
      );
    },
    updateStudent(state, action) {
      return {
        ...state,
        students: state.students.map((student) =>
          student.id === action.payload.id ? action.payload : student
        ),
      };
    },
  },
});

export const studentsActions = studentsSlice.actions;

export default studentsSlice;
