import { createSlice } from "@reduxjs/toolkit";

const subjectsSlice = createSlice({
  name: "subjects",
  initialState: {
    subjects: [],
    subject: {},
  },
  reducers: {
    setSubject(state, action) {
      state.subject = action.payload;
    },
    emptySubject(state, action) {
      state.subject = {};
    },
    loadSubjects(state, action) {
      state.subjects = action.payload;
    },
    addSubject(state, action) {
      const subject = {
        id: action.payload.id,
        name: action.payload.name,
        type: action.payload.type,
      };
      state.subjects.push(subject);
    },
    removeSubject(state, action) {
      state.subjects = state.subjects.filter(
        (subject) => subject.id !== action.payload
      );
    },
    updateSubject(state, action) {
      return {
        ...state,
        subjects: state.subjects.map((subject) =>
          subject.id === action.payload.id ? action.payload : subject
        ),
      };
    },
  },
});

export const subjectsActions = subjectsSlice.actions;

export default subjectsSlice;
