import React from "react";
import { Box, HStack, Text, Image, IconButton } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Logo from "../assets/images/hero.png";

function Header() {
  return (
    <Box
      className="bg-white col-span-12"
      boxShadow="lg"
      h="60px"
      position="relative"
    >
      <HStack h="60px" align="center" justify="space-between" spacing="50px">
        <Image h="60px" objectFit="contain" src={Logo} />
        <div></div>
      </HStack>
    </Box>
  );
}

export default Header;
