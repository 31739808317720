import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  Button,
} from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import Loading from "../pages/Loading";

function DeleteDialog({ open, onClose, message, title, onDelete }) {
  const loading = useSelector((state) => state.ui.loading);
  return (
    <AlertDialog isOpen={open} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>{message} </AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={onClose}>الغاء</Button>
            <Button colorScheme="red" onClick={() => onDelete()} mr={3}>
              حذف
            </Button>
          </AlertDialogFooter>
          {loading && <Loading />}
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default DeleteDialog;
