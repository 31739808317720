import {
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  Button,
  Spacer,
  Grid,
  GridItem,
  Text,
} from "@chakra-ui/react";
import React from "react";

function ShowGroup({ open, onClose, group }) {
  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalOverlay />
      <ModalContent sx={{ minWidth: "60%" }}>
        <ModalCloseButton sx={{ left: "1rem", right: "auto" }} />
        <Spacer />
        <ModalHeader>بيانات المجموعة {group ? group.name : ""}</ModalHeader>
        <ModalBody>
          <Grid templateColumns="repeat(12, 1fr)" gap="5px" rowGap="24px">
            <GridItem colSpan={12}>
              <Text className="text-slate-500">اسم المجموعة</Text>
              <Text>{group ? group.name : ""}</Text>
            </GridItem>
            <GridItem colSpan={12}>
              <Text className="text-slate-500">اللون</Text>
              <div
                className="h-12 rounded"
                style={{ backgroundColor: group ? group.bg_color : "#ffffff" }}
              ></div>
            </GridItem>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            الغاء
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ShowGroup;
