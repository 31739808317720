import {
  Box,
  HStack,
  Spacer,
  useToast,
  Text,
  Grid,
  GridItem,
  Divider,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import {
  faCheck,
  faMinus,
  faPenToSquare,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { variables } from "../constants";

function StudentInfo() {
  const dispatch = useDispatch();
  const toast = useToast();

  const user = useSelector((state) => state.auth);
  const student = useSelector((state) => state.students.student);

  const [openAddModel, setOpenAddModel] = useState(false);
  const [openShowModel, setOpenShowModel] = useState(false);
  const [openEditModel, setOpenEditModel] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  // useEffect(() => {
  //   axios
  //     .post(`${variables.url_api}/api/students`)
  //     .then((res) => {
  //       dispatch(studentsActions.loadStudents(res.data));
  //     })
  //     .catch((error) => console.log(error));
  // });

  const openToast = (type) => {
    if (type === "success") {
      toast({
        title: "تم حذف بيانات الطالب بنجاح",
        variant: "left-accent",
        position: "bottom-right",
        status: type,
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: "حصل خطأ في الحذف",
        variant: "left-accent",
        position: "bottom-right",
        status: type,
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <div className="overflow-scroll h-full no-scrollbar">
      <Box
        p="5px"
        overflow="scroll"
        className="bg-white no-scrollbar"
        borderTopWidth={5}
        borderTopColor="#60a5fa"
        borderRadius="lg"
      >
        <HStack pt="10px" pb="20px" px="20px">
          <Text className="text-2xl text-slate-600">بيانات الطالب</Text>
          <Spacer />
        </HStack>
        <Divider />
        <Grid
          templateColumns="repeat(12, 1fr)"
          gap="5px"
          rowGap="90px"
          className="py-10"
        >
          <GridItem colSpan={4}>
            <Text className="text-slate-500">الاسم</Text>
            <Text>{student ? student.name : ""}</Text>
          </GridItem>
          <GridItem colSpan={4}>
            <Text className="text-slate-500">رقم الموبايل</Text>
            <Text>{student ? student.phone_number : ""}</Text>
          </GridItem>
          <GridItem colSpan={4}>
            <Text className="text-slate-500">رقم موبايل ولي الامر</Text>
            <Text>{student ? student.parent_phone_number : ""}</Text>
          </GridItem>
          <GridItem colSpan={3}>
            <Text className="text-slate-500">تاريخ الميلاد</Text>
            <Text>{student ? student.birth_date : ""}</Text>
          </GridItem>
          <GridItem colSpan={3}>
            <Text className="text-slate-500">عنوان السكن</Text>
            <Text>{student ? student.address : ""}</Text>
          </GridItem>
          <GridItem colSpan={3}>
            <Text className="text-slate-500">اسم المدرسة</Text>
            <Text>{student ? student.school_name : ""}</Text>
          </GridItem>
          <GridItem colSpan={3}>
            <Text className="text-slate-500">المجموعة</Text>
            <Text>{student ? student.group.name : "-"}</Text>
          </GridItem>
        </Grid>
      </Box>
      <Box
        p="5px"
        overflow="scroll"
        className="bg-white no-scrollbar mt-5"
        borderTopWidth={5}
        borderTopColor="#60a5fa"
        borderRadius="lg"
      >
        <HStack pt="10px" pb="20px" px="20px">
          <Text className="text-2xl text-slate-600">الحضور</Text>
          <Spacer />
        </HStack>
        <Divider />
        <TableContainer className="mt-10 pb-10">
          <Table>
            <Thead>
              <Tr>
                <Th>تاريخ المحاضرة</Th>
                <Th>الحالة</Th>
              </Tr>
            </Thead>
            <Tbody>
              {student &&
                student.attendances.map((attendance, i) => (
                  <Tr key={i}>
                    <Td>{attendance.date}</Td>
                    <Td className="flex">
                      {attendance.status === "حاضر" ? (
                        <FontAwesomeIcon
                          key={i}
                          className="w-16 text-center"
                          icon={faCheck}
                          color="#22c55e"
                          size="xl"
                        />
                      ) : attendance.status === "مجاز" ? (
                        <FontAwesomeIcon
                          key={i}
                          className="w-16 text-center"
                          icon={faMinus}
                          color="#eab308"
                          size="xl"
                        />
                      ) : (
                        <FontAwesomeIcon
                          key={i}
                          className="w-16 text-center"
                          icon={faXmark}
                          color="#ef4444"
                          size="xl"
                        />
                      )}
                      <Text>{attendance.status}</Text>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      <Box
        p="5px"
        overflow="scroll"
        className="bg-white no-scrollbar mt-5"
        borderTopWidth={5}
        borderTopColor="#60a5fa"
        borderRadius="lg"
      >
        <HStack pt="10px" pb="20px" px="20px">
          <Text className="text-2xl text-slate-600">الامتحانات اليومية</Text>
          <Spacer />
        </HStack>
        <Divider />
        <TableContainer className="mt-10 pb-10">
          <Table>
            <Thead>
              <Tr>
                <Th>تاريخ الامتحان</Th>
                <Th>الاسالة</Th>
                <Th>الدرجة</Th>
                <Th>ملاحظات</Th>
              </Tr>
            </Thead>
            <Tbody>
              {student &&
                student.grades.map(
                  (grade, i) =>
                    grade.exam.type === "يومي" && (
                      <Tr key={i}>
                        <Td>{grade.exam.exam_date}</Td>
                        <Td>{grade.exam.question.name}</Td>
                        <Td>{grade.grade}</Td>
                        <Td>{grade.exam.name}</Td>
                      </Tr>
                    )
                )}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      <Box
        p="5px"
        overflow="scroll"
        className="bg-white no-scrollbar mt-5"
        borderTopWidth={5}
        borderTopColor="#60a5fa"
        borderRadius="lg"
      >
        <HStack pt="10px" pb="20px" px="20px">
          <Text className="text-2xl text-slate-600">الامتحانات الشهرية</Text>
          <Spacer />
        </HStack>
        <Divider />
        <TableContainer className="mt-10 pb-10">
          <Table>
            <Thead>
              <Tr>
                <Th>تاريخ الامتحان</Th>
                <Th>الاسالة</Th>
                <Th>الدرجة</Th>
                <Th>ملاحظات</Th>
              </Tr>
            </Thead>
            <Tbody>
              {student &&
                student.grades.map(
                  (grade, i) =>
                    grade.exam.type === "شهري" && (
                      <Tr key={i}>
                        <Td>{grade.exam.exam_date}</Td>
                        <Td>{grade.exam.question.name}</Td>
                        <Td>{grade.grade}</Td>
                        <Td>{grade.exam.name}</Td>
                      </Tr>
                    )
                )}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}

export default StudentInfo;
