import { createSlice } from "@reduxjs/toolkit";

const examsSlice = createSlice({
  name: "exams",
  initialState: {
    exams: [],
    exam: {},
    students: [],
  },
  reducers: {
    setExam(state, action) {
      state.exam = action.payload;
    },
    emptyExam(state, action) {
      state.exam = {};
    },
    setStudents(state, action) {
      state.students = action.payload;
    },
    changeGrade(state, action) {
      return {
        ...state,
        students: state.students.map((student) =>
          student.id === action.payload.id ? action.payload : student
        ),
      };
    },
    loadExams(state, action) {
      state.exams = action.payload;
    },
    addExam(state, action) {
      const exam = {
        id: action.payload.id,
        name: action.payload.name,
        type: action.payload.type,
        date: action.payload.date,
        question: action.payload.question,
        group: action.payload.group,
      };
      state.exams.push(exam);
    },
    removeExam(state, action) {
      state.exams = state.exams.filter((exam) => exam.id !== action.payload);
    },
    updateExam(state, action) {
      return {
        ...state,
        exams: state.exams.map((exam) =>
          exam.id === action.payload.id
            ? {
                name: action.payload.name,
                type: action.payload.type,
                date: action.payload.date,
                question: action.payload.question,
                group: action.payload.group,
              }
            : exam
        ),
      };
    },
  },
});

export const examsActions = examsSlice.actions;

export default examsSlice;
