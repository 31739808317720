import {
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  Button,
  Spacer,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberDecrementStepper,
  NumberIncrementStepper,
  Divider,
  useToast,
  FormErrorMessage,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { examsActions } from "../store/examsSlice";
import Loading from "../pages/Loading";
import { uiActions } from "../store/uiSlice";
import { variables } from "../constants";

function EditExam({ open, onClose }) {
  const dispatch = useDispatch();
  const toast = useToast();

  const groups = useSelector((state) => state.groups.groups);
  const questions = useSelector((state) => state.questions.questions);
  const exam = useSelector((state) => state.exams.exam);
  const students = useSelector((state) => state.exams.students);
  const loading = useSelector((state) => state.ui.loading);

  const [errors, setErrors] = useState({});

  //   const [students, setStudents] = useState([]);

  const handelOnChange = (label, value) => {
    let updatedExam = { ...exam };
    updatedExam[label] = value;

    dispatch(examsActions.setExam({ ...updatedExam }));
  };

  const handelOnChangeGrade = (student, value) => {
    const updatedStudent = { ...student };
    updatedStudent.grade = value;
    dispatch(examsActions.changeGrade({ ...updatedStudent }));
  };

  //   const handelOnChangeGroup = (id) => {
  //     handelOnChange("group_id", id);
  //     axios
  //       .post(`${variables.url_api}/api/attendances/show/${id}`)
  //       .then((res) => {
  //         let newStudents = [];
  //         setStudents([]);
  //         res.data.students.map((student) => {
  //           newStudents.push({
  //             id: student.id,
  //             name: student.name,
  //             grade: 0.0,
  //           });
  //         });
  //         setStudents([...newStudents]);
  //       })
  //       .catch((error) => console.log(error));
  //   };

  const openToast = (type) => {
    if (type === "success") {
      toast({
        title: "تم تحديث بيانات الامتحان بنجاح",
        variant: "left-accent",
        position: "bottom-right",
        status: type,
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: "حصل خطأ في التحديث",
        variant: "left-accent",
        position: "bottom-right",
        status: type,
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const validate = () => {
    let valid = true;
    let errors = {};

    if (!exam["name"]) {
      valid = false;
      errors.name = "اسم الامتحان لا يمكن ان يكون فارغاً";
    }

    if (!exam["type"]) {
      valid = false;
      errors.type = "يجب اختيار نوع الامتحان";
    }

    if (!exam["exam_date"]) {
      valid = false;
      errors.date = "تاريخ الامتحان لا يمكن ان يكون فارغاً";
    }

    if (!exam["group_id"]) {
      valid = false;
      errors.group = "يجب اختيار كروب الطالب";
    }

    if (!exam["question_id"]) {
      valid = false;
      errors.question = "يجب اختيار الاسالة";
    }

    setErrors(errors);
    return valid;
  };

  const handelEdit = () => {
    if (validate()) {
      dispatch(uiActions.setLoading(true));
      axios
        .post(`${variables.url_api}/api/exams/update/${exam.id}`, {
          ...exam,
          students,
        })
        .then((res) => {
          dispatch(uiActions.setLoading(false));
          openToast("success");
          onClose();
        })
        .catch((error) => {
          openToast("error");
          dispatch(uiActions.setLoading(false));
          console.log(error);
        });
    }
  };

  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalOverlay />
      <ModalContent sx={{ minWidth: "60%" }}>
        <ModalCloseButton sx={{ left: "1rem", right: "auto" }} />
        <Spacer />
        <ModalHeader>تعديل بيانات الامتحان </ModalHeader>
        <ModalBody>
          <Grid templateColumns="repeat(12, 1fr)" gap="5px">
            <GridItem colSpan={12}>
              <FormControl isInvalid={errors.name}>
                <FormLabel>اسم الامتحان</FormLabel>
                <Input
                  value={exam.name}
                  onChange={(e) => handelOnChange("name", e.target.value)}
                />
                <FormErrorMessage>{errors.name}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={6}>
              <FormControl isInvalid={errors.type}>
                <FormLabel>نوع الامتحان</FormLabel>
                <Select
                  placeholder="اختار نوع الامتحان"
                  value={exam.type}
                  onChange={(e) => handelOnChange("type", e.target.value)}
                >
                  <option value="شهري">شهري</option>
                  <option value="يومي">يومي</option>
                </Select>
                <FormErrorMessage>{errors.type}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={6}>
              <FormControl isInvalid={errors.date}>
                <FormLabel>تاريخ الامتحان</FormLabel>
                <Input
                  type="date"
                  value={exam.exam_date}
                  onChange={(e) => handelOnChange("exam_date", e.target.value)}
                />
                <FormErrorMessage>{errors.date}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={12}>
              <FormControl isInvalid={errors.question}>
                <FormLabel>الاسالة</FormLabel>
                <Select
                  placeholder="اختار الاسالة"
                  value={exam.question_id}
                  onChange={(e) =>
                    handelOnChange("question_id", e.target.value)
                  }
                >
                  {questions.map((q) => (
                    <option key={q.id} value={q.id}>
                      {q.name}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.question}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={12}>
              <FormControl isInvalid={errors.group}>
                <FormLabel>المجموعة</FormLabel>
                <Select
                  disabled
                  placeholder="اختار مجوعة الطلاب"
                  value={exam.group_id}
                  //   onChange={(e) => handelOnChangeGroup(e.target.value)}
                >
                  {groups.map((g) => (
                    <option key={g.id} value={g.id}>
                      {g.name}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.group}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={12}>
              <Text className="my-3 text-xl text-slate-500">درجات الطلاب</Text>
              <Divider />
            </GridItem>
            {students.map((student, i) => (
              <GridItem key={i} colSpan={12}>
                <div className="flex gap-5 justify-start my-2">
                  <Text>{student.name}</Text>
                  <Spacer />
                  <NumberInput
                    className="w-24"
                    min={0}
                    max={100}
                    value={student.grade}
                    onChange={(e) => handelOnChangeGrade(student, e)}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </div>
              </GridItem>
            ))}
          </Grid>
        </ModalBody>

        <ModalFooter>
          <Button
            variant="ghost"
            onClick={() => {
              onClose();
              setErrors({});
            }}
          >
            الغاء
          </Button>
          <Button colorScheme="blue" mr={3} onClick={handelEdit}>
            تعديل
          </Button>
        </ModalFooter>
        {loading && <Loading />}
      </ModalContent>
    </Modal>
  );
}

export default EditExam;
