import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import studentsSlice from "./studentsSlice";
import groupsSlice from "./groupsSlice";
import subjectsSlice from "./subjectsSlice";
import questionsSlice from "./questionsSlice";
import examsSlice from "./examsSlice";
import uiSlice from "./uiSlice";
import attendancesSlice from "./attendancesSlice";
import tablesSlice from "./tablesSlice";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    students: studentsSlice.reducer,
    attendances: attendancesSlice.reducer,
    groups: groupsSlice.reducer,
    subjects: subjectsSlice.reducer,
    questions: questionsSlice.reducer,
    exams: examsSlice.reducer,
    ui: uiSlice.reducer,
    tables: tablesSlice.reducer,
  },
});

export default store;
