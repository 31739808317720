import { createSlice } from "@reduxjs/toolkit";

const questionsSlice = createSlice({
  name: "questions",
  initialState: {
    questions: [],
    question: {},
  },
  reducers: {
    setQuestion(state, action) {
      state.question = action.payload;
    },
    emptyQuestion(state, action) {
      state.question = {};
    },
    loadQuestions(state, action) {
      state.questions = action.payload;
    },
    addQuestion(state, action) {
      const question = {
        id: action.payload.id,
        name: action.payload.name,
        subject_id: action.payload.subject_id,
        subject: action.payload.subject,
      };
      state.questions.push(question);
    },
    removeQuestion(state, action) {
      state.questions = state.questions.filter(
        (question) => question.id !== action.payload
      );
    },
    updateQuestion(state, action) {
      return {
        ...state,
        questions: state.questions.map((question) =>
          question.id === action.payload.id
            ? {
                id: action.payload.id,
                name: action.payload.name,
                subject_id: action.payload.subject_id,
                subject: action.payload.subject,
              }
            : question
        ),
      };
    },
  },
});

export const questionsAction = questionsSlice.actions;

export default questionsSlice;
