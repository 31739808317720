import { createSlice } from "@reduxjs/toolkit";

const groupsSlice = createSlice({
  name: "groups",
  initialState: {
    groups: [],
    group: {},
  },
  reducers: {
    setGroup(state, action) {
      state.group = action.payload;
    },
    emptyGroup(state, action) {
      state.group = {};
    },
    loadGroups(state, action) {
      state.groups = action.payload;
    },
    addGroup(state, action) {
      const group = {
        id: action.payload.id,
        name: action.payload.name,
      };
      state.groups.push(group);
    },
    removeGroup(state, action) {
      state.groups = state.groups.filter(
        (group) => group.id !== action.payload
      );
    },
    updateGroup(state, action) {
      return {
        ...state,
        groups: state.groups.map((group) =>
          group.id === action.payload.id ? action.payload : group
        ),
      };
    },
  },
});

export const groupsActions = groupsSlice.actions;

export default groupsSlice;
