import {
  Box,
  HStack,
  Button,
  Spacer,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddGroup from "../components/AddGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faPenToSquare,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { groupsActions } from "../store/groupsSlice";
import EditGroup from "../components/EditGroup";
import DeleteDialog from "../components/DeleteDialog";
import ShowGroup from "../components/ShowGroup";
import { uiActions } from "../store/uiSlice";
import Loading from "./Loading";
import { variables } from "../constants";

function Groups() {
  const dispatch = useDispatch();
  const toast = useToast();

  const user = useSelector((state) => state.auth);
  const groups = useSelector((state) => state.groups.groups);
  const loading = useSelector((state) => state.ui.loading);

  const [openAddModel, setOpenAddModel] = useState(false);
  const [openShowModel, setOpenShowModel] = useState(false);
  const [openEditModel, setOpenEditModel] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [group, setGroup] = useState(null);
  const [id, setId] = useState(null);

  const openToast = (type) => {
    if (type === "success") {
      toast({
        title: "تم حذف المجموعة بنجاح",
        variant: "left-accent",
        position: "bottom-right",
        status: type,
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: "حصل خطأ في الحذف",
        variant: "left-accent",
        position: "bottom-right",
        status: type,
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handelOpenShowModel = (group) => {
    dispatch(uiActions.setLoading(true));
    setGroup(group);
    setOpenShowModel(true);
    dispatch(uiActions.setLoading(false));
  };
  const handelOpenEditModel = (group) => {
    dispatch(groupsActions.setGroup(group));
    setOpenEditModel(true);
  };

  const handelDelete = (id) => {
    dispatch(uiActions.setLoading(true));
    axios
      .post(`${variables.url_api}/api/groups/delete/${id}`)
      .then((res) => {
        dispatch(groupsActions.removeGroup(id));
        dispatch(uiActions.setLoading(false));
        openToast("success");
        setOpenDeleteDialog(false);
      })
      .catch((error) => {
        openToast("error");
        dispatch(uiActions.setLoading(false));
        console.log(error);
      });
  };

  return (
    <Box
      h="100%"
      p="5px"
      overflow="scroll"
      className="bg-white"
      borderTopWidth={5}
      borderTopColor="#60a5fa"
      borderRadius="lg"
    >
      <HStack pt="10px" pb="20px" px="20px">
        <Spacer />
        <Button colorScheme="blue" onClick={() => setOpenAddModel(true)}>
          اضافة مجموعة
        </Button>
      </HStack>
      <TableContainer>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>الاسم</Th>
              <Th>التحكم</Th>
            </Tr>
          </Thead>
          <Tbody>
            {groups.map((group, i) => (
              <Tr key={i}>
                <Td>{group.name}</Td>
                <Td>
                  <IconButton
                    bg="transparent"
                    borderRadius="3xl"
                    aria-label="عرض المجموعة"
                    onClick={() => handelOpenShowModel(group)}
                    icon={
                      <FontAwesomeIcon
                        className="text-blue-400"
                        icon={faFile}
                      />
                    }
                  />
                  <IconButton
                    bg="transparent"
                    borderRadius="3xl"
                    aria-label="تعديل المجموعة"
                    onClick={() => handelOpenEditModel(group)}
                    icon={
                      <FontAwesomeIcon
                        className="text-blue-400"
                        icon={faPenToSquare}
                      />
                    }
                  />
                  <IconButton
                    bg="transparent"
                    borderRadius="3xl"
                    aria-label="حذف المجموعة"
                    onClick={() => {
                      setOpenDeleteDialog(true);
                      setId(group.id);
                    }}
                    icon={
                      <FontAwesomeIcon
                        className="text-blue-400"
                        icon={faTrashCan}
                      />
                    }
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <AddGroup open={openAddModel} onClose={() => setOpenAddModel(false)} />
      <ShowGroup
        open={openShowModel}
        onClose={() => setOpenShowModel(false)}
        group={group}
      />
      <EditGroup open={openEditModel} onClose={() => setOpenEditModel(false)} />
      <DeleteDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        message={"هل انت متأكد من حذف المجموعة"}
        title={"حذف المجموعة"}
        onDelete={() => handelDelete(id)}
      />
      {loading && <Loading />}
    </Box>
  );
}

export default Groups;
