import {
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  Button,
  Spacer,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  Divider,
  RadioGroup,
  Radio,
  Stack,
  useToast,
  FormErrorMessage,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { attendancesActions } from "../store/attendancesSlice";
import Loading from "../pages/Loading";
import { uiActions } from "../store/uiSlice";
import { variables } from "../constants";

function EditAttendance({ open, onClose, group }) {
  const dispatch = useDispatch();
  const toast = useToast();

  const attendance = useSelector((state) => state.attendances.attendance);
  const students = useSelector((state) => state.attendances.students);
  const groups = useSelector((state) => state.groups.groups);
  const loading = useSelector((state) => state.ui.loading);

  const [errors, setErrors] = useState({});

  const handelOnChangeAttendance = (student, value) => {
    const updatedStudent = { ...student };
    updatedStudent.status = value;
    dispatch(attendancesActions.changeStatus({ ...updatedStudent }));
  };

  const openToast = (type) => {
    if (type === "success") {
      toast({
        title: "تم تحديث بيانات الحضور بنجاح",
        variant: "left-accent",
        position: "bottom-right",
        status: type,
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: "حصل خطأ في التحديث",
        variant: "left-accent",
        position: "bottom-right",
        status: type,
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const validate = () => {
    let valid = true;
    let errors = {};

    if (!attendance["date"]) {
      valid = false;
      errors.date = "تاريخ الحضور لا يمكن ان يكون فارغاً";
    }

    if (!attendance["group_id"]) {
      valid = false;
      errors.group = "يجب اختيار كروب الطالب";
    }

    setErrors(errors);
    return valid;
  };

  const handelEdit = () => {
    dispatch(uiActions.setLoading(true));
    axios
      .post(`${variables.url_api}/api/attendances/update`, {
        date: attendance.date,
        students,
      })
      .then((res) => {
        dispatch(uiActions.setLoading(false));
        openToast("success");
        onClose();
      })
      .catch((error) => {
        openToast("error");
        dispatch(uiActions.setLoading(false));
        console.log(error);
      });
  };

  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalOverlay />
      <ModalContent sx={{ minWidth: "60%" }}>
        <ModalCloseButton sx={{ left: "1rem", right: "auto" }} />
        <Spacer />
        <ModalHeader>تعديل حضور الطلاب</ModalHeader>
        <ModalBody>
          <Grid templateColumns="repeat(12, 1fr)" gap="5px">
            <GridItem colSpan={7}>
              <FormControl>
                <FormLabel>المجموعة</FormLabel>
                <Select placeholder="اختار مجوعة الطلاب" value={group} disabled>
                  {groups.map((g) => (
                    <option key={g.id} value={g.id}>
                      {g.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem colSpan={5}>
              <FormControl>
                <FormLabel>تاريخ المحاضرة</FormLabel>
                <Input type="date" value={attendance.date} disabled />
              </FormControl>
            </GridItem>
            <GridItem colSpan={12}>
              <Text className="mt-5 text-xl">الطلاب</Text>
              <Divider />
            </GridItem>
            {students.map((student, i) => (
              <GridItem key={i} colSpan={12}>
                <div className="flex gap-5 justify-start my-2">
                  <Text>{student.name}</Text>
                  <Spacer />
                  <RadioGroup
                    className="ml-10"
                    onChange={(e) => handelOnChangeAttendance(student, e)}
                    value={student.status}
                  >
                    <Stack spacing={5} direction="row">
                      <Radio colorScheme="green" value="حاضر">
                        حاضر
                      </Radio>
                      <Radio colorScheme="orange" value="مجاز">
                        مجاز
                      </Radio>
                      <Radio colorScheme="red" value="غائب">
                        غائب
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </div>
              </GridItem>
            ))}
          </Grid>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            الغاء
          </Button>
          <Button colorScheme="blue" mr={3} onClick={handelEdit}>
            تعديل
          </Button>
        </ModalFooter>
        {loading && <Loading />}
      </ModalContent>
    </Modal>
  );
}

export default EditAttendance;
