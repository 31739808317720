import {
  Box,
  HStack,
  Button,
  Spacer,
  Text,
  IconButton,
  Select,
  Divider,
  useToast,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddExam from "../components/AddExam";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { examsActions } from "../store/examsSlice";
import EditExam from "../components/EditExam";
import DeleteDialog from "../components/DeleteDialog";
import { uiActions } from "../store/uiSlice";
import { variables } from "../constants";

function Exams() {
  const dispatch = useDispatch();
  const toast = useToast();

  const user = useSelector((state) => state.auth);
  const groups = useSelector((state) => state.groups.groups);

  const [group, setGroup] = useState("");
  const [exam, setExam] = useState("");

  const [openAddModel, setOpenAddModel] = useState(false);
  const [openEditModel, setOpenEditModel] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [students, setStudents] = useState([]);
  const [dailyExams, setDailyExams] = useState([]);
  const [monthlyExams, setMonthlyExams] = useState([]);
  const [id, setId] = useState(null);

  const openToast = (type) => {
    if (type === "success") {
      toast({
        title: "تم حذف بيانات الامتحان بنجاح",
        variant: "left-accent",
        position: "bottom-right",
        status: type,
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: "حصل خطأ في الحذف",
        variant: "left-accent",
        position: "bottom-right",
        status: type,
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handelOpenEditModel = (id) => {
    axios
      .post(`${variables.url_api}/api/exams/show/${id}`)
      .then((res) => {
        const studentsArray = [];
        console.log(res.data);
        dispatch(examsActions.setExam({ ...res.data[0].exam }));
        res.data.forEach((grade) => {
          studentsArray.push({
            id: grade.id,
            name: grade.student.name,
            grade: grade.grade,
          });
        });
        dispatch(examsActions.setStudents([...studentsArray]));
      })
      .catch((error) => console.log(error));
    setOpenEditModel(true);
  };

  const handelDelete = (id) => {
    dispatch(uiActions.setLoading(true));
    axios
      .post(`${variables.url_api}/api/exams/delete/${id}`)
      .then((res) => {
        setOpenDeleteDialog(false);
        dispatch(uiActions.setLoading(false));
        openToast("success");
        setOpenDeleteDialog(false);
      })
      .catch((error) => {
        openToast("error");
        dispatch(uiActions.setLoading(false));
        console.log(error);
      });
  };

  const handelOnChange = (id) => {
    setGroup(id);
    axios
      .post(`${variables.url_api}/api/exams`, {
        group: id,
      })
      .then((res) => {
        setDailyExams([...res.data[0]]);
        setMonthlyExams([...res.data[1]]);
        setStudents([...res.data[2]]);
      })
      .catch((error) => console.log(error));
  };

  return (
    <Box
      h="100%"
      p="5px"
      overflow="scroll"
      className="bg-white no-scrollbar"
      borderTopWidth={5}
      borderTopColor="#60a5fa"
      borderRadius="lg"
    >
      <HStack pt="10px" pb="20px" px="20px">
        <Spacer />
        <Button colorScheme="blue" onClick={() => setOpenAddModel(true)}>
          اضافة امتحان
        </Button>
      </HStack>
      <HStack pt="10px" pb="20px" px="20px">
        <Select
          placeholder="اختار مجوعة الطلاب"
          value={group}
          onChange={(e) => handelOnChange(e.target.value)}
        >
          {groups.map((g) => (
            <option key={g.id} value={g.id}>
              {g.name}
            </option>
          ))}
        </Select>
      </HStack>
      <div className="w-full mb-8 overflow-scroll no-scrollbar">
        <div className="pb-5">
          <Text className="text-xl">الامتحانات الشهرية</Text>
        </div>
        <div className="flex  w-fit gap-5 justify-start">
          <div className="flex flex-col w-fit gap-5 justify-start">
            <Text className="font-bold h-16 w-96 text-slate-500">
              اسم الطالب
            </Text>
            {students.map((student, i) => (
              <Text key={i} className="w-96">
                {student.name}
              </Text>
            ))}
          </div>
          {monthlyExams.map((exam, i) => (
            <div className="flex flex-col w-fit gap-5 justify-start">
              <div key={i} className="w-fit h-16 ">
                <Text className="w-18 text-xs text-center font-bold text-slate-500">
                  {exam.name}
                </Text>
                <Text className="w-18 text-xs text-center font-bold text-slate-500">
                  {exam.exam_date}
                </Text>
                <div className="flex justify-center">
                  <IconButton
                    bg="transparent"
                    borderRadius="3xl"
                    size="sm"
                    aria-label="تعديل بيانات الامتحان"
                    onClick={() => handelOpenEditModel(exam.id)}
                    icon={
                      <FontAwesomeIcon
                        className="text-blue-400"
                        icon={faPenToSquare}
                      />
                    }
                  />
                  <IconButton
                    bg="transparent"
                    borderRadius="3xl"
                    size="sm"
                    aria-label="حذف بيانات الامتحان"
                    onClick={() => {
                      setOpenDeleteDialog(true);
                      setExam(exam);
                    }}
                    icon={
                      <FontAwesomeIcon
                        className="text-blue-400"
                        icon={faTrashCan}
                      />
                    }
                  />
                </div>
              </div>
              {exam.grades.map((grade, i) => (
                <Text key={i} className="text-center">
                  {students.map(
                    (student) => grade.student_id === student.id && grade.grade
                  )}
                </Text>
              ))}
            </div>
          ))}
        </div>
      </div>
      <Divider />
      <div className="w-full my-8 overflow-scroll no-scrollbar">
        <div className="pb-5">
          <Text className="text-xl">الامتحانات اليومية</Text>
        </div>
        <div className="flex  w-fit gap-5 justify-start">
          <div className="flex flex-col w-fit gap-5 justify-start">
            <Text className="font-bold h-16 w-96 text-slate-500">
              اسم الطالب
            </Text>
            {students.map((student, i) => (
              <Text key={i} className="w-96">
                {student.name}
              </Text>
            ))}
          </div>
          {dailyExams.map((exam, i) => (
            <div className="flex flex-col w-fit gap-5 justify-start">
              <div key={i} className="w-fit h-16 ">
                <Text className="w-18 text-xs text-center font-bold text-slate-500">
                  {exam.name}
                </Text>
                <Text className="w-18 text-xs text-center font-bold text-slate-500">
                  {exam.exam_date}
                </Text>
                <div className="flex justify-center">
                  <IconButton
                    bg="transparent"
                    borderRadius="3xl"
                    size="sm"
                    aria-label="تعديل بيانات الامتحان"
                    onClick={() => handelOpenEditModel(exam.id)}
                    icon={
                      <FontAwesomeIcon
                        className="text-blue-400"
                        icon={faPenToSquare}
                      />
                    }
                  />
                  <IconButton
                    bg="transparent"
                    borderRadius="3xl"
                    size="sm"
                    aria-label="حذف بيانات الامتحان"
                    onClick={() => {
                      setOpenDeleteDialog(true);
                      setExam(exam);
                    }}
                    icon={
                      <FontAwesomeIcon
                        className="text-blue-400"
                        icon={faTrashCan}
                      />
                    }
                  />
                </div>
              </div>
              {exam.grades.map((grade, i) => (
                <Text key={i} className="text-center">
                  {students.map(
                    (student) => grade.student_id === student.id && grade.grade
                  )}
                </Text>
              ))}
            </div>
          ))}
        </div>
      </div>
      <AddExam open={openAddModel} onClose={() => setOpenAddModel(false)} />
      <EditExam open={openEditModel} onClose={() => setOpenEditModel(false)} />
      <DeleteDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        message={"هل انت متأكد من حذف بيانات الامتحان"}
        title={"حذف بيانات الامتحان"}
        onDelete={() => handelDelete(exam.id)}
      />
    </Box>
  );
}

export default Exams;
