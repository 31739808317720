import React from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import Header from "./header";
import SideNav from "./SideNav";
import { Outlet } from "react-router-dom";

function Layout() {
  return (
    <div dir="rtl">
      <Grid h="100vh" templateColumns="repeat(12, 1fr)">
        <GridItem zIndex={10} colSpan={12}>
          <Header />
        </GridItem>
        <GridItem zIndex={9} rowSpan={2} colSpan={2}>
          <SideNav />
        </GridItem>
        <GridItem
          className="bg-slate-200"
          h="calc(100vh - 60px)"
          colSpan={10}
          p="20px"
        >
          <Outlet />
        </GridItem>
      </Grid>
    </div>
  );
}

export default Layout;
