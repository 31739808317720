import React from "react";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";

function UploadFile({ onDrop, file }) {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
    },
    onDrop,
  });

  return (
    <section className="w-full h-5/6 border rounded cursor-pointer">
      <div
        className=" h-full flex flex-col justify-center content-center"
        {...getRootProps()}
      >
        {file ? (
          <p className="text-center p-4 text-slate-400">{file.name}</p>
        ) : (
          <>
            <input {...getInputProps} />
            <FontAwesomeIcon color="#94a3b8" size="xl" icon={faPaperclip} />
            <p className="text-center p-4 text-slate-400">
              اسحب الملف هنا او اضغط لاختيار الملف
            </p>
          </>
        )}
      </div>
    </section>
  );
}

export default UploadFile;
