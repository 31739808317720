import {
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  Button,
  Spacer,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Input,
  Select,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { studentsActions } from "../store/studentsSlice";
import { uiActions } from "../store/uiSlice";
import Loading from "../pages/Loading";
import { variables } from "../constants";
import { tablesActions } from "../store/tablesSlice";

function AddTable({ open, onClose }) {
  const dispatch = useDispatch();
  const toast = useToast();

  const groups = useSelector((state) => state.groups.groups);
  const loading = useSelector((state) => state.ui.loading);

  const days = [
    "السبت",
    "الاحد",
    "الاثنين",
    "الثلاثاء",
    "الاربعاء",
    "الخميس",
    "الجمعة",
  ];

  const [day, setDay] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [group, setGroup] = useState("");
  const [errors, setErrors] = useState({});

  const openToast = (type) => {
    if (type === "success") {
      toast({
        title: "تم اضافة المحاضرة بنجاح",
        variant: "left-accent",
        position: "bottom-right",
        status: type,
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: "حصل خطأ في الاضافة",
        variant: "left-accent",
        position: "bottom-right",
        status: type,
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const validate = () => {
    let valid = true;
    let errors = {};

    if (!group) {
      valid = false;
      errors.group = "يجب اختيار كروب الطالب";
    }

    if (!day) {
      valid = false;
      errors.day = "يجب اختيار اليوم";
    }

    if (!startTime) {
      valid = false;
      errors.startTime = "يجب اختيار وقت بدء المحاضرة";
    }

    if (!endTime) {
      valid = false;
      errors.endTime = "يجب اختيار وقت انتهاء المحاضرة";
    }

    setErrors(errors);
    return valid;
  };

  const handelAdd = () => {
    if (validate()) {
      dispatch(uiActions.setLoading(true));
      axios
        .post(`${variables.url_api}/api/tables/create`, {
          day: day,
          start_time: startTime,
          end_time: endTime,
          group_id: group,
        })
        .then((res) => {
          dispatch(tablesActions.addTable(res.data));
          setDay("");
          setStartTime("");
          setEndTime("");
          setGroup("");
          dispatch(uiActions.setLoading(false));
          openToast("success");
          onClose();
        })
        .catch((error) => {
          openToast("error");
          dispatch(uiActions.setLoading(false));
          console.log(error);
        });
    }
  };
  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalOverlay />
      <ModalContent sx={{ minWidth: "60%" }}>
        <ModalCloseButton sx={{ left: "1rem", right: "auto" }} />
        <Spacer />
        <ModalHeader>اضافة محاضرة جديدة</ModalHeader>
        <ModalBody>
          <Grid templateColumns="repeat(12, 1fr)" gap="5px">
            <GridItem colSpan={12}>
              <FormControl isInvalid={errors.group}>
                <FormLabel>المجموعة</FormLabel>
                <Select
                  placeholder="اختار مجوعة الطلاب"
                  value={group}
                  onChange={(e) => setGroup(e.target.value)}
                >
                  {groups.map((g) => (
                    <option key={g.id} value={g.id}>
                      {g.name}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.group}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={12}>
              <FormControl isInvalid={errors.group}>
                <FormLabel>اليوم</FormLabel>
                <Select
                  placeholder="اختار اليوم"
                  value={day}
                  onChange={(e) => setDay(e.target.value)}
                >
                  {days.map((day) => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.day}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={6}>
              <FormControl isInvalid={errors.startTime}>
                <FormLabel>وقت بدء المحاضرة</FormLabel>
                <Input
                  type="time"
                  min={"08:00:00"}
                  max={"21:00:00"}
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                />
                <FormErrorMessage>{errors.startTime}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={6}>
              <FormControl isInvalid={errors.endTime}>
                <FormLabel>وقت انتهاء المحاضرة</FormLabel>
                <Input
                  type="time"
                  min={"08:00:00"}
                  max={"21:00:00"}
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                />
                <FormErrorMessage>{errors.endTime}</FormErrorMessage>
              </FormControl>
            </GridItem>
          </Grid>
        </ModalBody>

        <ModalFooter>
          <Button
            variant="ghost"
            onClick={() => {
              onClose();
              setDay("");
              setStartTime("");
              setEndTime("");
              setGroup("");
              setErrors({});
            }}
          >
            الغاء
          </Button>
          <Button colorScheme="blue" mr={3} onClick={handelAdd}>
            اضافة
          </Button>
        </ModalFooter>
        {loading && <Loading />}
      </ModalContent>
    </Modal>
  );
}

export default AddTable;
