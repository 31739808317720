import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authActions } from "../store/authSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faLock,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  Flex,
  Box,
  Text,
  Divider,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  Button,
  Image,
  InputLeftElement,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";

import Logo from "../assets/images/logo.png";
import { studentsActions } from "../store/studentsSlice";
import { groupsActions } from "../store/groupsSlice";
import { subjectsActions } from "../store/subjectsSlice";
import { questionsAction } from "../store/questionsSlice";
import { tablesActions } from "../store/tablesSlice";

import { variables } from "../constants";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [error, setError] = useState("");

  useEffect(() => {
    axios
      .post(`${variables.url_api}/api/students`)
      .then((res) => {
        dispatch(studentsActions.loadStudents(res.data));
      })
      .catch((error) => console.log(error));

    axios
      .post(`${variables.url_api}/api/groups`)
      .then((res) => {
        dispatch(groupsActions.loadGroups(res.data));
      })
      .catch((error) => console.log(error));

    axios
      .post(`${variables.url_api}/api/subjects`)
      .then((res) => {
        dispatch(subjectsActions.loadSubjects(res.data));
      })
      .catch((error) => console.log(error));

    axios
      .post(`${variables.url_api}/api/questions`)
      .then((res) => {
        dispatch(questionsAction.loadQuestions(res.data));
      })
      .catch((error) => console.log(error));

    axios
      .post(`${variables.url_api}/api/tables`)
      .then((res) => {
        dispatch(tablesActions.loadTables(res.data));
      })
      .catch((error) => console.log(error));

    // const handleKeyDown = (event) => {
    //   if (event.key === "Enter") {
    //     handleLogin();
    //   }
    // };

    // window.addEventListener("keydown", handleKeyDown);

    // return () => {
    //   window.removeEventListener("keydown", handleKeyDown);
    // };
  }, []);

  const validate = () => {
    let valid = true;
    let errors = {};

    if (!userName) {
      valid = false;
      errors.userName = "اسم المستخدم لا يمكن ان لا يكون فارغاً";
    }

    if (!password) {
      valid = false;
      errors.password = "كلمة المرور لا يمكن ان لا تكون فارغة";
    }

    setErrors(errors);
    return valid;
  };

  const handleLogin = () => {
    setError("");
    if (validate()) {
      const credentials = {
        email: userName,
        password: password,
      };

      axios
        .post(`${variables.url_api}/api/auth/login`, credentials)
        .then((res) => {
          const user = {
            id: res.data.user.id,
            name: res.data.user.name,
            userName: res.data.user.email,
            token: res.data.token,
          };

          dispatch(authActions.login(user));
          navigate("/students");
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setError("اسم المستخدم او كلمة المرور خاطئة");
          } else {
            setError("حدث خطأ ما. يرجى المحاولة مرة أخرى لاحقًا");
          }
          console.log(error);
        });
    }
  };

  return (
    <div className="bg-slate-200 h-screen w-screen">
      <Flex
        alignItems="center"
        justifyContent="center"
        h="100%"
        direction="column"
      >
        <Image boxSize="200" objectFit="contain" src={Logo} />
        <Box
          w={300}
          pb="3"
          className="bg-white"
          borderTopWidth={5}
          borderTopColor="#60a5fa"
          borderRadius="lg"
        >
          <Text className="font-bold m-3">تسجيل الدخول</Text>
          <Divider />
          <Flex p={3} flexDirection="column" gap={3}>
            <FormControl isInvalid={errors.userName || error}>
              <InputGroup>
                <Input
                  value={userName}
                  placeholder="المستخدم"
                  onChange={(e) => setUserName(e.target.value)}
                />
                <InputLeftElement>
                  <FontAwesomeIcon color="#94a3b8" icon={faUser} />
                </InputLeftElement>
              </InputGroup>
              <FormErrorMessage>{errors.userName}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.password || error}>
              <InputGroup>
                <Input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="كلمة المرور"
                  type={show ? "text" : "password"}
                />
                <InputRightElement>
                  <IconButton
                    size="sm"
                    variant="ghost"
                    onClick={() => setShow(!show)}
                    icon={
                      !show ? (
                        <FontAwesomeIcon color="#60a5fa" icon={faEye} />
                      ) : (
                        <FontAwesomeIcon color="#60a5fa" icon={faEyeSlash} />
                      )
                    }
                  />
                </InputRightElement>
                <InputLeftElement>
                  <FontAwesomeIcon color="#94a3b8" icon={faLock} />
                </InputLeftElement>
              </InputGroup>
              <FormErrorMessage>{errors.password}</FormErrorMessage>
            </FormControl>
            <Button colorScheme="blue" onClick={handleLogin}>
              تسجيل الدخول
            </Button>
            {error && (
              <Text className="text-center text-red-500 text-sm">{error}</Text>
            )}
          </Flex>
        </Box>
      </Flex>
      {/* <Loading /> */}
    </div>
  );
}

export default Login;
