import {
  Box,
  Text,
  HStack,
  Spacer,
  Button,
  useToast,
  IconButton,
} from "@chakra-ui/react";
import React, { useState } from "react";
import AddTable from "../components/AddTable";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { uiActions } from "../store/uiSlice";
import { variables } from "../constants";
import { tablesActions } from "../store/tablesSlice";
import axios from "axios";
import Loading from "./Loading";
import DeleteDialog from "../components/DeleteDialog";

const Tables = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const times = [
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
  ];

  const user = useSelector((state) => state.auth);
  const tables = useSelector((state) => state.tables.tables);
  const loading = useSelector((state) => state.ui.loading);

  const [openAddModel, setOpenAddModel] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [id, setId] = useState(null);

  const openToast = (type) => {
    if (type === "success") {
      toast({
        title: "تم حذف المحاضرة بنجاح",
        variant: "left-accent",
        position: "bottom-right",
        status: type,
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: "حصل خطأ في الحذف",
        variant: "left-accent",
        position: "bottom-right",
        status: type,
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const event = (table) => {
    let startHour = moment(table.start_time, "HH:mm:ss").hours();
    let endHour = moment(table.end_time, "HH:mm:ss").hours();
    let startMinutes = moment(table.start_time, "HH:mm:ss").minutes();
    let endMinutes = moment(table.end_time, "HH:mm:ss").minutes();
    let top = (startHour - 8 + (startMinutes - 0) / 60) * 2 * 2.25 + 3;
    let height =
      (endHour - startHour + (endMinutes - startMinutes) / 60) * 2 * 2.25 - 0.5;

    return (
      <div
        className="flex flex-col rounded-lg my-1 px-2 border-2 justify-center"
        style={{
          position: "absolute",
          top: `${top}rem`,
          height: `${height}rem`,
          backgroundColor: `${table.group.bg_color}`,
          width: `12.5%`,
        }}
      >
        <Text className="text-sm text-center text-white">
          {table.group.name}
        </Text>
        <Text className="text-sm text-center text-white">{`${startHour}:${startMinutes} - ${endHour}:${endMinutes}`}</Text>
        <IconButton
          bg="transparent"
          size="sm"
          borderRadius="3xl"
          aria-label="حذف المحاضرة"
          onClick={() => {
            setOpenDeleteDialog(true);
            setId(table.id);
          }}
          icon={<FontAwesomeIcon className="text-white" icon={faTrashCan} />}
        />
      </div>
    );
  };

  const handelDelete = (id) => {
    dispatch(uiActions.setLoading(true));
    axios
      .post(`${variables.url_api}/api/tables/delete/${id}`)
      .then((res) => {
        dispatch(tablesActions.removeTable(id));
        dispatch(uiActions.setLoading(false));
        openToast("success");
        setOpenDeleteDialog(false);
      })
      .catch((error) => {
        openToast("error");
        dispatch(uiActions.setLoading(false));
        console.log(error);
      });
  };

  return (
    <Box
      h="100%"
      p="5px"
      overflow="scroll"
      className="bg-white no-scrollbar"
      borderTopWidth={5}
      borderTopColor="#60a5fa"
      borderRadius="lg"
    >
      <HStack pt="10px" pb="20px" px="20px">
        <Spacer />
        <Button colorScheme="blue" onClick={() => setOpenAddModel(true)}>
          اضافة محاضرة
        </Button>
      </HStack>
      <div className="grid grid-cols-8 relative">
        <div className="">
          <div className="p-1 h-12 border-l"></div>
          {times.map((time, i) => (
            <div key={i} className="p-1 h-9 border-l">
              <Text className="text-center -mt-4">{time}</Text>
            </div>
          ))}
        </div>
        <div className="">
          <div className="p-1 h-12 border-b">
            <Text className="text-center">السبت</Text>
          </div>
          {times.map((_, i) => (
            <div key={i} className="p-1 h-9 border-b border-l" />
          ))}
          {tables &&
            tables.map((table, i) => table.day === "السبت" && event(table))}
        </div>

        <div className="">
          <div className="p-1 h-12 border-b">
            <Text className="text-center">الاحد</Text>
          </div>
          {times.map((_, i) => (
            <div key={i} className="p-1 h-9 border-b border-l" />
          ))}
          {tables &&
            tables.map((table, i) => table.day === "الاحد" && event(table))}
        </div>
        <div className="">
          <div className="p-1 h-12 border-b">
            <Text className="text-center">الاثنين</Text>
          </div>
          {times.map((_, i) => (
            <div key={i} className="p-1 h-9 border-b border-l" />
          ))}
          {tables &&
            tables.map((table, i) => table.day === "الاثنين" && event(table))}
        </div>
        <div className="">
          <div className="p-1 h-12 border-b">
            <Text className="text-center">الثلاثاء</Text>
          </div>
          {times.map((_, i) => (
            <div key={i} className="p-1 h-9 border-b border-l" />
          ))}
          {tables &&
            tables.map((table, i) => table.day === "الثلاثاء" && event(table))}
        </div>
        <div className="">
          <div className="p-1 h-12 border-b">
            <Text className="text-center">الاربعاء</Text>
          </div>
          {times.map((_, i) => (
            <div key={i} className="p-1 h-9 border-b border-l" />
          ))}
          {tables &&
            tables.map((table, i) => table.day === "الاربعاء" && event(table))}
        </div>
        <div className="">
          <div className="p-1 h-12 border-b">
            <Text className="text-center">الخميس</Text>
          </div>
          {times.map((_, i) => (
            <div key={i} className="p-1 h-9 border-b border-l" />
          ))}
          {tables &&
            tables.map((table, i) => table.day === "الخميس" && event(table))}
        </div>
        <div className="">
          <div className="p-1 h-12 border-b">
            <Text className="text-center">الجمعة</Text>
          </div>
          {times.map((_, i) => (
            <div key={i} className="p-1 h-9 border-b " />
          ))}
          {tables &&
            tables.map((table, i) => table.day === "الجمعة" && event(table))}
        </div>
      </div>
      <AddTable open={openAddModel} onClose={() => setOpenAddModel(false)} />
      <DeleteDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        message={"هل انت متأكد من حذف المحاضرة من الجدول"}
        title={"حذف المحاضرة"}
        onDelete={() => handelDelete(id)}
      />
      {loading && <Loading />}
    </Box>
  );
};

export default Tables;
