import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";
import Login from "./pages/Login";
import Students from "./pages/Students";
import Home from "./pages/Home";
import Groups from "./pages/Groups";
import Subjects from "./pages/Subjects";
import Questions from "./pages/Questions";
import Exams from "./pages/Exams";
import Attendances from "./pages/Attendances";
import Landing from "./pages/Landing";
import LandingRoute from "./utils/LandingRoute";
import StudentInfo from "./pages/StudentInfo";
import Tables from "./pages/Tables";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route element={<Home />} path="/"></Route>
            <Route element={<Students />} path="/students"></Route>
            <Route element={<Groups />} path="/groups"></Route>
            <Route element={<Subjects />} path="/subjects"></Route>
            <Route element={<Questions />} path="/questions"></Route>
            <Route element={<Exams />} path="/exams"></Route>
            <Route element={<Attendances />} path="/attendances"></Route>
            <Route element={<StudentInfo />} path="/studentinfo"></Route>
            <Route element={<Tables />} path="/tables"></Route>
          </Route>
          {/* <Route element={<LandingRoute />}> */}
          <Route element={<Login />} path="/login" />
          {/* </Route> */}
          {/* <Route element={<Landing />} path="/" /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
