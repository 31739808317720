import { createSlice } from "@reduxjs/toolkit";

const tablesSlice = createSlice({
  name: "students",
  initialState: {
    tables: [],
    table: {},
    currentPage: 1,
  },
  reducers: {
    setTable(state, action) {
      state.table = action.payload;
    },
    emptyTable(state, action) {
      state.table = {};
    },
    loadTables(state, action) {
      state.tables = action.payload;
    },
    addTable(state, action) {
      const table = {
        id: action.payload.id,
        day: action.payload.day,
        start_time: action.payload.start_time,
        end_time: action.payload.end_time,
        group_id: action.payload.group_id,
        group: action.payload.group,
      };
      state.tables.push(table);
    },
    removeTable(state, action) {
      state.tables = state.tables.filter(
        (table) => table.id !== action.payload
      );
    },
    updateTable(state, action) {
      return {
        ...state,
        tables: state.tables.map((table) =>
          table.id === action.payload.id ? action.payload : table
        ),
      };
    },
  },
});

export const tablesActions = tablesSlice.actions;

export default tablesSlice;
