import {
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  Button,
  Spacer,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Input,
  Select,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { studentsActions } from "../store/studentsSlice";
import { uiActions } from "../store/uiSlice";
import Loading from "../pages/Loading";
import { variables } from "../constants";

function AddStudent({ open, onClose }) {
  const dispatch = useDispatch();
  const toast = useToast();

  const groups = useSelector((state) => state.groups.groups);
  const loading = useSelector((state) => state.ui.loading);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [parentPhone, setParentPhone] = useState("");
  const [birthDay, setBirthDay] = useState("");
  const [address, setAddress] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [group, setGroup] = useState("");
  const [errors, setErrors] = useState({});

  const openToast = (type) => {
    if (type === "success") {
      toast({
        title: "تم اضافة بيانات الطالب بنجاح",
        variant: "left-accent",
        position: "bottom-right",
        status: type,
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: "حصل خطأ في الاضافة",
        variant: "left-accent",
        position: "bottom-right",
        status: type,
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const validate = () => {
    let valid = true;
    let errors = {};

    if (!name) {
      valid = false;
      errors.name = "اسم الطالب لا يمكن ان يكون فارغاً";
    }

    if (!phone) {
      valid = false;
      errors.phone = "رقم الهاتف لا يمكن ان يكون فارغاً";
    } else if (phone.length !== 11) {
      valid = false;
      errors.phone = "رقم الهاتف غير صحيح";
    }

    if (!parentPhone) {
      valid = false;
      errors.parentPhone = "رقم الهاتف لا يمكن ان يكون فارغاً";
    } else if (parentPhone.length !== 11) {
      valid = false;
      errors.parentPhone = "رقم الهاتف غير صحيح";
    }

    if (!group) {
      valid = false;
      errors.group = "يجب اختيار كروب الطالب";
    }

    setErrors(errors);
    return valid;
  };

  const handelAdd = () => {
    if (validate()) {
      dispatch(uiActions.setLoading(true));
      axios
        .post(`${variables.url_api}/api/students/create`, {
          name: name,
          phone_number: phone,
          parent_phone_number: parentPhone,
          birth_date: birthDay,
          address: address,
          school_name: schoolName,
          group_id: group,
        })
        .then((res) => {
          dispatch(studentsActions.addStudent(res.data));
          setName("");
          setPhone("");
          setParentPhone("");
          setBirthDay("");
          setAddress("");
          setSchoolName("");
          setGroup("");
          dispatch(uiActions.setLoading(false));
          openToast("success");
          onClose();
        })
        .catch((error) => {
          openToast("error");
          dispatch(uiActions.setLoading(false));
          console.log(error);
        });
    }
  };
  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalOverlay />
      <ModalContent sx={{ minWidth: "60%" }}>
        <ModalCloseButton sx={{ left: "1rem", right: "auto" }} />
        <Spacer />
        <ModalHeader>اضافة طالب جديد</ModalHeader>
        <ModalBody>
          <Grid templateColumns="repeat(12, 1fr)" gap="5px">
            <GridItem colSpan={12}>
              <FormControl isInvalid={errors.name}>
                <FormLabel>الاسم</FormLabel>
                <Input value={name} onChange={(e) => setName(e.target.value)} />
                <FormErrorMessage>{errors.name}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={6}>
              <FormControl isInvalid={errors.phone}>
                <FormLabel>رقم الموبايل</FormLabel>
                <Input
                  value={phone}
                  maxLength={11}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <FormErrorMessage>{errors.phone}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={6}>
              <FormControl isInvalid={errors.parentPhone}>
                <FormLabel>رقم موبايل ولي الامر</FormLabel>
                <Input
                  value={parentPhone}
                  maxLength={11}
                  onChange={(e) => setParentPhone(e.target.value)}
                />
                <FormErrorMessage>{errors.parentPhone}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={4}>
              <FormControl>
                <FormLabel>تاريخ الميلاد</FormLabel>
                <Input
                  type="date"
                  value={birthDay}
                  onChange={(e) => setBirthDay(e.target.value)}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={4}>
              <FormControl>
                <FormLabel>عنوان السكن</FormLabel>
                <Input
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={4}>
              <FormControl>
                <FormLabel>اسم المدرسة</FormLabel>
                <Input
                  value={schoolName}
                  onChange={(e) => setSchoolName(e.target.value)}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={12}>
              <FormControl isInvalid={errors.group}>
                <FormLabel>المجموعة</FormLabel>
                <Select
                  placeholder="اختار مجوعة الطلاب"
                  value={group}
                  onChange={(e) => setGroup(e.target.value)}
                >
                  {groups.map((g) => (
                    <option key={g.id} value={g.id}>
                      {g.name}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.group}</FormErrorMessage>
              </FormControl>
            </GridItem>
          </Grid>
        </ModalBody>

        <ModalFooter>
          <Button
            variant="ghost"
            onClick={() => {
              onClose();
              setName("");
              setPhone("");
              setParentPhone("");
              setBirthDay("");
              setAddress("");
              setSchoolName("");
              setGroup("");
              setErrors({});
            }}
          >
            الغاء
          </Button>
          <Button colorScheme="blue" mr={3} onClick={handelAdd}>
            اضافة
          </Button>
        </ModalFooter>
        {loading && <Loading />}
      </ModalContent>
    </Modal>
  );
}

export default AddStudent;
