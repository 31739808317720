import {
  Box,
  HStack,
  Button,
  Spacer,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddQuestion from "../components/AddQuestion";
import { faFile, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { questionsAction } from "../store/questionsSlice";
import axios from "axios";
import ShowQuestion from "../components/ShowQuestion";
import EditQuestion from "../components/EditQuestion";
import DeleteDialog from "../components/DeleteDialog";
import { uiActions } from "../store/uiSlice";
import Loading from "./Loading";
import { variables } from "../constants";

function Questions() {
  const dispatch = useDispatch();
  const toast = useToast();

  const user = useSelector((state) => state.auth);
  const questions = useSelector((state) => state.questions.questions);
  const loading = useSelector((state) => state.ui.loading);

  const [openAddModel, setOpenAddModel] = useState(false);
  const [openShowModel, setOpenShowModel] = useState(false);
  const [openEditModel, setOpenEditModel] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [question, setQuestion] = useState(null);
  const [file, setFile] = useState(null);
  const [id, setId] = useState(null);

  const openToast = (type) => {
    if (type === "success") {
      toast({
        title: "تم حذف الاسالة بنجاح",
        variant: "left-accent",
        position: "bottom-right",
        status: type,
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: "حصل خطأ في الحذف",
        variant: "left-accent",
        position: "bottom-right",
        status: type,
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handelOpenShowModel = (question) => {
    dispatch(uiActions.setLoading(true));
    axios
      .post(`${variables.url_api}/api/questions/show/${question.id}`)
      .then((res) => {
        setFile(res.data);
        setQuestion(question);
        setOpenShowModel(true);
        dispatch(uiActions.setLoading(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(uiActions.setLoading(false));
      });
  };

  const handelOpenEditModel = (question) => {
    dispatch(questionsAction.setQuestion(question));
    setOpenEditModel(true);
  };

  const handelDelete = (id) => {
    dispatch(uiActions.setLoading(true));
    axios
      .post(`${variables.url_api}/api/questions/delete/${id}`)
      .then((res) => {
        dispatch(questionsAction.removeQuestion(id));
        dispatch(uiActions.setLoading(false));
        openToast("success");
        setOpenDeleteDialog(false);
      })
      .catch((error) => {
        openToast("error");
        dispatch(uiActions.setLoading(false));
        console.log(error);
      });
  };

  return (
    <Box
      h="100%"
      p="5px"
      overflow="scroll"
      className="bg-white"
      borderTopWidth={5}
      borderTopColor="#60a5fa"
      borderRadius="lg"
    >
      <HStack pt="10px" pb="20px" px="20px">
        <Spacer />
        <Button colorScheme="blue" onClick={() => setOpenAddModel(true)}>
          اضافة اسالة
        </Button>
      </HStack>
      <TableContainer>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>الاسم</Th>
              <Th>الموضوع</Th>
              <Th>التحكم</Th>
            </Tr>
          </Thead>
          <Tbody>
            {questions.map((question) => (
              <Tr key={question.id}>
                <Td>{question.name}</Td>
                <Td>{question.subject ? question.subject.name : "-"}</Td>
                <Td>
                  <IconButton
                    bg="transparent"
                    borderRadius="3xl"
                    aria-label="عرض الاسالة"
                    onClick={() => handelOpenShowModel(question)}
                    icon={
                      <FontAwesomeIcon
                        className="text-blue-400"
                        icon={faFile}
                      />
                    }
                  />
                  <IconButton
                    bg="transparent"
                    borderRadius="3xl"
                    aria-label="تعديل الاسالة"
                    onClick={() => handelOpenEditModel(question)}
                    icon={
                      <FontAwesomeIcon
                        className="text-blue-400"
                        icon={faPenToSquare}
                      />
                    }
                  />
                  <IconButton
                    bg="transparent"
                    borderRadius="3xl"
                    aria-label="حذف الاسالة"
                    onClick={() => {
                      setOpenDeleteDialog(true);
                      setId(question.id);
                    }}
                    icon={
                      <FontAwesomeIcon
                        className="text-blue-400"
                        icon={faTrashCan}
                      />
                    }
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <AddQuestion open={openAddModel} onClose={() => setOpenAddModel(false)} />
      <ShowQuestion
        open={openShowModel}
        onClose={() => setOpenShowModel(false)}
        question={question}
        file={file}
      />
      <EditQuestion
        open={openEditModel}
        onClose={() => setOpenEditModel(false)}
      />
      <DeleteDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        message={"هل انت متأكد من حذف الاسالة"}
        title={"حذف الاسالة"}
        onDelete={() => handelDelete(id)}
      />
      {loading && <Loading />}
    </Box>
  );
}

export default Questions;
